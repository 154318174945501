import {useParams} from "react-router-dom";

import styles from "./css/Book.module.css"
import {useEffect, useState} from "react";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";
import NotFound from "../error/NotFound";
import Cover from "./Cover";
import CommaSeparatedPeopleList from "../people/CommaSeparatedPeopleList"
import CommaSeparatedPublisherList from "../publisher/CommaSeparatedPublisherList";
import Year from "./Year";
import Pages from "./Pages";
import Format from "./Format";
import Isbn from "./Isbn";
import Translation from "./Translation";
import CoverArt from "./CoverArt";
import Description from "./Description";
import Contents from "./Contents";
import LibrariesButtons from "./LibrariesButtons";
import {FormattedMessage} from "react-intl";

function Book() {
    const {bookId} = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [book, setBook] = useState(null)
    useEffect(() => {
        function loadBook(id) {
            fetch(`${process.env.REACT_APP_API_HOST}/books/${id}`)
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                    throw new Error(res.status.toString())
                })
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setBook(result);
                        document.title = "Lectorem: " + result.title;
                    },
                    (err) => {
                        setIsLoaded(true);
                        setError(err);
                    }
                )
        }

        loadBook(bookId);
    }, [bookId])
    if (error) {
        if (error.message === "404") {
            return <NotFound/>
        }
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        return (
            <>
                <div>
                    <Cover id={bookId} title={book.title}/>
                    <LibrariesButtons bookId={bookId} />
                </div>
                <div className={styles.bookProperties} itemScope itemType="https://schema.org/Book">
                    <h1 itemProp="name">{book.title}</h1>
                    {book.type === "DEFAULT" &&
                        <h4>
                            <CommaSeparatedPeopleList
                                people={getAuthors(book.contents)}
                                name={it => it.name}/>
                        </h4>
                    }
                    {book.type === "ANTHOLOGY" && book.editors.length > 0 &&
                        <h4>
                            {book.editors.length > 1 ?
                                <FormattedMessage id="properties.editors" />
                                :
                                <FormattedMessage id="properties.editor" />
                            }
                            : <CommaSeparatedPeopleList
                                people={book.editors}
                                name={it => it.name}/>
                        </h4>
                    }
                    <div className={styles.bookAdditionalProperties}>
                        <p>
                            <CommaSeparatedPublisherList publishers={book.publishers}/>
                            <Year year={book.year} />
                            <Pages pages={book.pages} />
                        </p>
                        <Format format={book.format} coverType={book.coverType} />
                        <Isbn isbn={book.isbn} />
                    </div>
                    <Translation contents={book.contents} />
                    <CoverArt artists={book.artists} />
                    <Description description={book.description}/>
                    <Contents contents={book.contents} />
                </div>
            </>
        )
    }
}

function getAuthors(contents) {
    return uniqBy(contents.filter(item => !item.addition).flatMap(i => i.authors), it => it.id)
}

function uniqBy(a, key) {
    let seen = new Set();
    return a.filter(item => {
        let k = key(item);
        return seen.has(k) ? false : seen.add(k);
    });
}

export default Book
