import styles from "../form/css/AddItem.module.css"
import {useEffect, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import PeopleService from "../../services/PeopleService";
import {Navigate} from "react-router-dom";
import ErrorBlock from "../error/ErrorBlock";
import AuthService from "../../services/AuthService";
import Resizer from "react-image-file-resizer";
import CountrySelector from "../form/CountrySelector";

function AddPerson({personDraft}) {
    personDraft = personDraft || undefined
    const intl = useIntl()
    const [id ,setId] = useState(null)
    const [person, setPerson] = useState({
        firstName: "",
        lastName: "",
        originalFirstName: "",
        originalLastName: "",
        country: "",
        sex: "",
        born: "",
        died: "",
        image: null
    })
    const [image, setImage] = useState(null)

    const resizeFile = (file, width, height) =>
        new Promise((resolve) => {
            if (width < 350 || height < 470) {
                console.error("Image to small!")
                return
            }
            const widthRate = width/350;
            const heightRate = height/470;

            let maxWidth = 350
            let maxHeight = 470
            if (widthRate > heightRate) {
                maxWidth = Math.round((width * maxHeight) / height);
            } else {
                maxHeight = Math.round((height * maxWidth) / width);
            }

            Resizer.imageFileResizer(
                file,
                maxWidth,
                maxHeight,
                "WEBP",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    const onChange = async (event) => {
        try {
            const file = event.target.files[0];
            const reader = new FileReader()
            reader.onload = async () => {
                const img = new Image()
                img.onload = async () => {
                    const resizedImage = resizeFile(file, img.width, img.height);
                    resizedImage.then(result => setImage(result))
                }
                img.src = reader.result
            }
            reader.readAsDataURL(file)
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (personDraft) {
            setPerson({
                id: personDraft.id,
                firstName: personDraft.firstName || "",
                lastName: personDraft.lastName || "",
                originalFirstName: personDraft.originalFirstName || "",
                originalLastName: personDraft.originalLastName || "",
                country: personDraft.country?.id || "",
                sex: personDraft.sex || "",
                born: personDraft.born || "",
                died: personDraft.died || ""
            })
        }
    }, [personDraft])

    const [error, setError] = useState(null)
    const handleSubmit = (e) => {
        // TODO disable button
        e.preventDefault()
        person.image = image
        PeopleService.create(person)
            .then(p => setId(p.data))
            .catch(err => setError(err))
    }
    if (id) {
        if (AuthService.isAdmin()) {
            return <Navigate to={`/people/${id}`} />
        } else {
            return <Navigate to={`/issues`} />
        }
    } else if (error) {
        console.error(error)
        return <ErrorBlock />
    } else {
        if (AuthService.getCurrentUser()) {
            return (
                <div className={styles.formContainer}>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.addItemContainer}>
                            <div className={styles.inputGroup}>
                                <div className={styles.row}>
                                    <div className={`${styles.inputGroup} ${styles.mainInRow}`}>
                                        <label htmlFor="firstName">
                                            <FormattedMessage id="properties.firstName" />
                                        </label>
                                        <input type="text" id="firstName" name="firstName"
                                               placeholder={intl.formatMessage({id : "placeholders.firstName"})}
                                               onChange={(e) => setPerson({...person, firstName: e.target.value})}
                                               value={person.firstName}
                                               required />
                                    </div>
                                    <div className={`${styles.inputGroup} ${styles.mainInRow}`}>
                                        <label htmlFor="lastName">
                                            <FormattedMessage id="properties.lastName" />
                                        </label>
                                        <input type="text" id="lastName" name="lastName"
                                               placeholder={intl.formatMessage({id : "placeholders.lastName"})}
                                               onChange={(e) => setPerson({...person, lastName: e.target.value})}
                                               value={person.lastName}
                                               required />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.inputGroup}>
                                <div className={styles.row}>
                                    <div className={`${styles.inputGroup} ${styles.mainInRow}`}>
                                        <label htmlFor="originalFirstName">
                                            <FormattedMessage id="properties.originalFirstName" />
                                        </label>
                                        <input type="text" id="originalFirstName" name="originalFirstName"
                                               placeholder={intl.formatMessage({id : "placeholders.originalFirstName"})}
                                               onChange={(e) => setPerson({...person, originalFirstName: e.target.value})}
                                               value={person.originalFirstName}
                                               required />
                                    </div>
                                    <div className={`${styles.inputGroup} ${styles.mainInRow}`}>
                                        <label htmlFor="originalLastName">
                                            <FormattedMessage id="properties.originalLastName" />
                                        </label>
                                        <input type="text" id="originalLastName" name="originalLastName"
                                               placeholder={intl.formatMessage({id : "placeholders.originalLastName"})}
                                               onChange={(e) => setPerson({...person, originalLastName: e.target.value})}
                                               value={person.originalLastName}
                                               required />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.inputGroup}>
                                <div className={styles.row}>
                                    <div className={styles.inputGroup}>
                                        <label htmlFor="country">
                                            <FormattedMessage id="properties.country" />
                                        </label>
                                        <CountrySelector updater={it => setPerson({...person, country: it})} />
                                    </div>
                                    <div className={styles.inputGroup}>
                                        <label htmlFor="sex">
                                            <FormattedMessage id="properties.sex" />
                                        </label>
                                        <select name="sex" id="sex"
                                                onChange={(e) => setPerson({...person, sex: e.target.value})}
                                                value={person.sex}
                                                required>
                                            <option value="">
                                                -
                                            </option>
                                            <option value="MALE">
                                                <FormattedMessage id="properties.sex.MALE" />
                                            </option>
                                            <option value="FEMALE">
                                                <FormattedMessage id="properties.sex.FEMALE" />
                                            </option>
                                        </select>
                                    </div>
                                    <div className={styles.inputGroup}>
                                        <label htmlFor="born">
                                            <FormattedMessage id="properties.born.MALE" />
                                        </label>
                                        <input type="date" name="born" id="born"
                                               onChange={(e) => setPerson({...person, born: e.target.value})}
                                               value={person.born} />
                                    </div>
                                    <div className={styles.inputGroup}>
                                        <label htmlFor="died">
                                            <FormattedMessage id="properties.died.MALE" />
                                        </label>
                                        <input type="date" name="died" id="died"
                                               onChange={(e) => setPerson({...person, died: e.target.value})}
                                               value={person.died} />
                                    </div>
                                </div>
                            </div>
                            <input type="file" onChange={onChange} />
                            <img className={styles.imagePreview} src={image} alt="" />
                            <input type="submit" value={intl.formatMessage({id: "properties.submit"})} />
                        </div>
                    </form>
                </div>
            )
        } else {
            return <ErrorBlock />
        }
    }
}

export default AddPerson
