import styles from "./css/Contents.module.css";
import {FormattedMessage} from "react-intl";
import CommaSeparatedPeopleList from "../people/CommaSeparatedPeopleList";

function Contents({contents}) {
    if (contents && contents.length > 1) {
        return(
            <div className={styles.contents}>
                <h3>
                    <FormattedMessage id="properties.book.contents"/>:
                </h3>
                <ul>
                    {contents.map(item => (
                        <li key={item.position}>
                            <CommaSeparatedPeopleList people={item.authors} name={it => it.name} />
                            .&nbsp;
                            <a href={!item.translation ?
                                `/works/${item.workId}` :
                                `/translations/${item.translation.id}`}>
                                {item.title}
                            </a>
                            {item.translation &&
                                <span>
                                &nbsp;(
                                <span>
                                    {item.translation.from !== "rus" ?
                                        <>
                                            <FormattedMessage id="prefix.translation.from.small"/>
                                            &nbsp;
                                            <FormattedMessage id={`languages.from.${item.translation.from}`}/>
                                        </>
                                        :
                                        <>
                                            <FormattedMessage id="prefix.translation.small"/>
                                        </>
                                    }
                                </span>
                                    &nbsp;
                                    <CommaSeparatedPeopleList people={item.translation.translators}
                                                              name={it => it.conjugatedName} />
                                )
                            </span>
                            }
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}

export default Contents
