import styles from "./css/PersonWorks.module.css";
import {useState, useEffect} from "react";
import PersonItemsHeader from "./PersonItemsHeader";
import PersonTranslation from "./PersonTranslation";
import NotFound from "../error/NotFound";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";

function PersonTranslations({personId}) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [translations, setTranslations] = useState([])

    useEffect(() => {
        function loadPersonWorks(id) {
            fetch(`${process.env.REACT_APP_API_HOST}/people/${id}/translations`)
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                    throw new Error(res.status.toString())
                })
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setTranslations(result);
                    },
                    (err) => {
                        setIsLoaded(true);
                        setError(err);
                    }
                )
        }

        loadPersonWorks(personId);
    }, [personId])

    if (error) {
        if (error.message === "404") {
            return <NotFound/>
        }
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        if (translations && translations.length > 0) {
            return(
                <div className={styles.personWorksBlock}>
                    <PersonItemsHeader title="properties.translations" />
                    {translations.map(translation => (
                        <PersonTranslation key={translation.id} translation={translation} />
                    ))}
                </div>
            )
        }
    }
}

export default PersonTranslations
