import {apiClient} from "./Api";

class TranslationService {
    create(translation) {
        return apiClient.post(process.env.REACT_APP_API_HOST + "/translations", translation);
    }

    getById(id) {
        return apiClient.get(process.env.REACT_APP_API_HOST + "/translations/" + id)
    }
}

export default new TranslationService()
