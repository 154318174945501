import styles from "./css/Work.module.css"
import {useEffect, useState} from "react";
import CommaSeparatedPeopleList from "../people/CommaSeparatedPeopleList";
import WorkBooks from "./WorkBooks";
import {useParams} from "react-router-dom";
import NotFound from "../error/NotFound";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";
import AuthService from "../../services/AuthService";
import {FormattedMessage, FormattedNumber} from "react-intl";
import WorkTranslations from "./WorkTranslations";
import Mark from "../rating/Mark";
import WorkService from "../../services/WorkService";
import WorkReviews from "./WorkReviews";
import WorkMovies from "./WorkMovies";

function Work() {
    const {workId} = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [work, setWork] = useState(null)
    const [rating, setRating] = useState({marks: 0, rating: 0})
    useEffect(() => {
        function loadWork(id) {
            WorkService.getById(id)
                .then(res =>  res.data)
                .then(result => {
                        setIsLoaded(true);
                        setWork(result);
                        setRating(result.rating)
                        document.title = "Lectorem: " + result.title;
                    }
                )
                .catch(err => setError(err))
        }

        loadWork(workId);
    }, [workId])
    if (error) {
        if (error.message === "404") {
            return <NotFound/>
        }
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        return(
            <div itemScope className={styles.workBlocks}>
                <div className={styles.workHeader}>
                    <div>
                        <div className={styles.workProperties}>
                            <h4>
                                <CommaSeparatedPeopleList people={work.authors} name={it => it.name} />
                            </h4>
                            <h1>
                                <span>
                                    {work.mostPublishedTranslation}
                                </span>

                            </h1>
                            <h3>
                                <span>
                                    {work.title}
                                </span>
                            </h3>
                            <Mark workId={work.id} currentValue={work.mark?.mark.toString()} setRating={setRating} />
                            {rating.marks > 0 &&
                                <p>
                                    <FormattedMessage id="properties.rating" />: <FormattedNumber value={rating.rating} minimumFractionDigits={2} />
                                    &nbsp;(<FormattedMessage id="properties.work.marks" values={{marks: rating.marks}} />)
                                </p>
                            }
                        </div>
                        <WorkTranslations translations={work.translations} />
                    </div>
                </div>
                <WorkMovies workId={workId} />
                <WorkBooks books={work.books} />
                <WorkReviews workId={workId} />
                {AuthService.isAdmin() &&
                    <>
                        <div className="link indent">
                            <a href={`/works/${workId}/translations/add`} >
                                <FormattedMessage id="links.translation.add" />
                            </a>
                            <span> | </span>
                            <a href={`/works/${workId}/books/add`} >
                                <FormattedMessage id="links.book.add" />
                            </a>
                            <span> | </span>
                            <a href={`/works/${workId}/movies/add`} >
                                <FormattedMessage id="links.movie.add" />
                            </a>
                        </div>
                    </>
                }
            </div>
        )
    }
}

export default Work
