import styles from "../form/css/AddItem.module.css"
import {FormattedMessage, useIntl} from "react-intl";
import SearchableInput from "../form/SearchableInput";
import {useState} from "react";
import {Navigate, useParams} from "react-router-dom";
import BookService from "../../services/BookService";
import AuthService from "../../services/AuthService";
import ErrorBlock from "../error/ErrorBlock";
import AdvancedBookPositionSearchableInput from "../form/AdvancedBookPositionSearchableInput";

function AddBook() {
    const intl = useIntl()
    const {workId, translationId} = useParams()

    const [id ,setId] = useState(null)
    const [error, setError] = useState(null)
    const [book, setBook] = useState(
        {
            contents: [
                {
                    position: 1,
                    workId: workId,
                    translationId: translationId,
                    addition: false
                }
            ]
        }
    )

    function onSubmit() {
        return (e) => {
            e.preventDefault();
            BookService.create(book)
                .then(p => setId(p.data))
                .catch(err => setError(err))
        };
    }

    if (id) {
        if (AuthService.isAdmin()) {
            return <Navigate to={`/books/${id}`} />
        } else {
            return <Navigate to={`/issues`} />
        }
    } else if (error) {
        console.error(error)
        return <ErrorBlock />
    } else {
        if (AuthService.getCurrentUser()) {
            return (
                <div className={styles.formContainer}>
                    <form onSubmit={onSubmit()}>
                        <div className={styles.addItemContainer}>
                            <div className={styles.inputGroup}>
                                <label htmlFor="title">
                                    <FormattedMessage id="properties.title"/>
                                </label>
                                <input type="text" id="title" name="title"
                                       onChange={(e) => setBook({...book, title: e.target.value})}
                                       required/>
                            </div>
                            <div>
                                <label>
                                    <FormattedMessage id="properties.publisher"/>
                                </label>
                                <SearchableInput updater={it => setBook({...book, publishers: it})} type="publisher"/>
                            </div>
                            <div className={styles.inputGroup}>
                                <div className={styles.row}>
                                    <div className={styles.inputGroup}>
                                        <label htmlFor="year">
                                            <FormattedMessage id="properties.year"/>
                                        </label>
                                        <input className={styles.number} name="year" id="year" pattern="[0-9]{4}"
                                               onChange={(e) => setBook({...book, year: e.target.value})}
                                        />
                                    </div>
                                    <div className={styles.inputGroup}>
                                        <label htmlFor="pages">
                                            <FormattedMessage id="properties.pages"/>
                                        </label>
                                        <input className={styles.number} name="pages" id="pages" pattern="[0-9]+"
                                               onChange={(e) => setBook({...book, pages: e.target.value})}
                                        />
                                    </div>
                                    <div className={`${styles.inputGroup} ${styles.mainInRow}`}>
                                        <label htmlFor="isbn">ISBN</label>
                                        <input name="isbn" id="isbn"
                                               pattern="^(?=[0-9]{13}$|(?=(?:[0-9]+[- ]){4})[- 0-9]{17}$)97[89][- ]?[0-9]{1,5}[- ]?[0-9]+[- ]?[0-9]+[- ]?[0-9]$"
                                               placeholder="ISBN-13, e.g.: 978-0-596-52068-7"
                                               onChange={(e) => setBook({...book, isbn: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.inputGroup}>
                                <div className={styles.row}>
                                    <div className={styles.inputGroup}>
                                        <label htmlFor="cover-type">
                                            <FormattedMessage id="properties.coverType"/>
                                        </label>
                                        <select name="coverType" id="cover-type"
                                                onChange={(e) => setBook({...book, coverType: e.target.value})}
                                        >
                                            <option>-</option>
                                            <option value="HARD">
                                                <FormattedMessage id="cover.types.HARD"/>
                                            </option>
                                            <option value="SOFT">
                                                <FormattedMessage id="cover.types.SOFT"/>
                                            </option>
                                        </select>
                                    </div>
                                    <div className={styles.inputGroup}>
                                        <label htmlFor="format">
                                            <FormattedMessage id="properties.format"/>
                                        </label>
                                        <input name="format" id="format" pattern="[0-9]+×[0-9]+/[0-9]+"
                                               placeholder="e.g.: 60×90/16"
                                               onChange={(e) => setBook({...book, format: e.target.value})}
                                        />
                                    </div>
                                    <div className={`${styles.inputGroup} ${styles.mainInRow}`}>
                                        <label htmlFor="cover">
                                            <FormattedMessage id="properties.cover"/>
                                        </label>
                                        <input name="cover" id="cover"
                                               pattern="^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b)([-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$"
                                               onChange={(e) => setBook({...book, cover: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.inputGroup}>
                                <label htmlFor="description">
                                    <FormattedMessage id="properties.bookDescription" />
                                </label>
                                <textarea id="description" name="description" rows={5}
                                          onChange={(e) => setBook({...book, description: e.target.value})}
                                />
                            </div>
                            <div>
                                <label>
                                    <FormattedMessage id="properties.book.contents" />
                                </label>
                                <AdvancedBookPositionSearchableInput contents={book.contents} updater={it => setBook({...book, contents: it})} />
                            </div>
                            <input type="submit" value={intl.formatMessage({id: "properties.submit"})}/>
                        </div>
                    </form>
                </div>
            )
        }
    }
}

export default AddBook
