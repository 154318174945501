import styles from "./css/WorkBook.module.css";
import CommaSeparatedPublisherList from "../publisher/CommaSeparatedPublisherList";
import Year from "../book/Year";
import Pages from "../book/Pages";
import Isbn from "../book/Isbn";

function WorkBook({book}) {
    return(
        <div className={styles.workBookRow}>
            <div className={styles.bookSmallCover}>
                <a href={`/books/${book.id}`}>
                    <img src={`${process.env.REACT_APP_IMAGE_HOST}/books/${book.id}.webp`}
                         alt={book.title} />
                </a>
            </div>
            <div className={styles.workBookDetails}>
                <div className={styles.workBookTitle}>
                    <h4>
                        <span>
                            {book.title}
                        </span>
                    </h4>
                    <p className="link">
                        <CommaSeparatedPublisherList publishers={book.publishers}/>
                        <Year year={book.year} />
                        <Pages pages={book.pages} />
                    </p>
                    <p className="link">
                        <Isbn isbn={book.isbn} />
                    </p>
                </div>
            </div>
        </div>
    )
}

export default WorkBook
