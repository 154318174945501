import styles from "./css/PersonWork.module.css";
import {FormattedMessage} from "react-intl";

function PersonItemsHeader({title}) {
    return(
        <div className={styles.personWorkRow}>
            <p className={styles.personBlockHeader}>
                <FormattedMessage id={title} />
            </p>
        </div>
    )
}

export default PersonItemsHeader
