import styles from "./css/PersonPhoto.module.css";

function PersonPhoto({id, name}) {
    return(
        <div className={styles.personPhoto}>
            <img itemProp="image"
                 src={`${process.env.REACT_APP_IMAGE_HOST}/people/${id}.webp`}
                 alt={name}
            />
        </div>
    )
}

export default PersonPhoto
