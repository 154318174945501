import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import NotFound from "../error/NotFound";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";
import styles from "../work/css/Work.module.css";
import CommaSeparatedPeopleList from "../people/CommaSeparatedPeopleList";
import TranslationDetails from "./TranslationDetails";
import WorkBooks from "../work/WorkBooks";
import AuthService from "../../services/AuthService";
import {FormattedMessage} from "react-intl";

function Translation() {
    const {translationId} = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [translation, setTranslation] = useState(null)
    useEffect(() => {
        function loadTranslation(id) {
            fetch(`${process.env.REACT_APP_API_HOST}/translations/${id}`)
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                    throw new Error(res.status.toString())
                })
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setTranslation(result);
                        document.title = "Lectorem: " + result.title;
                    },
                    (err) => {
                        setIsLoaded(true);
                        setError(err);
                    }
                )
        }

        loadTranslation(translationId);
    }, [translationId])
    if (error) {
        if (error.message === "404") {
            return <NotFound/>
        }
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        return (
            <div itemScope className={styles.workBlocks}>
                <div className={styles.workProperties}>
                    <h4>
                        <CommaSeparatedPeopleList people={translation.work.authors} name={it => it.name}/>
                    </h4>
                    <h1>
                        <span>
                            {translation.title}
                        </span>
                    </h1>
                    <h3>
                        <a href={`/works/${translation.work.id}`}>
                            {translation.work.title}
                        </a>
                    </h3>
                    <p>
                        <TranslationDetails from={translation.from} translators={translation.translators} />
                    </p>
                </div>
                <WorkBooks books={translation.books} />
                {AuthService.isAdmin() &&
                    <>
                        <div className="link indent">
                            <a href={`/translations/${translationId}/books/add`} >
                                <FormattedMessage id="links.book.add" />
                            </a>
                        </div>
                    </>
                }
            </div>
        )
    }
}

export default Translation
