import styles from "./css/Library.module.css"
import {useEffect, useState} from "react";
import LibraryService from "../../services/LibraryService";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";
import {useParams, useSearchParams} from "react-router-dom";
import Pagination from "../pagination/Pagination";
import {FormattedMessage} from "react-intl";

const Library = () => {
    const {libraryId} = useParams();
    const [searchParams] = useSearchParams();
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false);
    const [library, setLibrary] = useState(null);
    const [books, setBooks] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalBooks, setTotalBooks] = useState(0);
    const [pageable, setPageable] = useState({
        sort: {
            empty: true,
            sorted: false,
            unsorted: true
        },
        offset: 0,
        pageNumber: 0,
        pageSize: 20,
        unpaged: false,
        paged: true
    });

    useEffect(() => {
        const page = searchParams.get('page');
        const size = searchParams.get('size');

        LibraryService.getById(libraryId, page, size)
            .then(p => {
                setIsLoaded(true)
                setLibrary(p.data.library)
                setBooks(p.data.content)
                setTotalPages(p.data.totalPages)
                setPageable(p.data.pageable)
                setTotalBooks(p.data.totalElements)
            })
            .catch(err => setError(err))
    }, [libraryId, searchParams])
    if (error) {
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        return (
            <div className={styles.libraryContainer}>
                <center>
                    <h2>
                        {library.name}
                    </h2>
                </center>
                <p>
                    <small>
                        <FormattedMessage id="properties.number.of.books"/>: {totalBooks}
                    </small>
                </p>
                <div className={styles.booksContainer}>
                    {books.map(book => (
                        <div key={book.id} itemScope={true} itemType="https://schema.org/Book">
                            <a href={"/books/" + book.id}>
                                <img
                                    src={process.env.REACT_APP_IMAGE_HOST + "/books/" + book.id + ".webp"}
                                    alt={book.title}
                                />
                            </a>
                            <p itemProp="name">{book.title}</p>
                        </div>
                    ))}
                </div>
                <Pagination baseUrl={`/libraries/${libraryId}`}
                            totalPages={totalPages}
                            page={pageable.pageNumber}
                            size={pageable.pageSize}/>
            </div>
        )
    }
}

export default Library
