import styles from "./css/PersonWorks.module.css";
import {useState, useEffect} from "react";
import PersonWork from "./PersonWork";
import PersonItemsHeader from "./PersonItemsHeader";
import NotFound from "../error/NotFound";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";
import PeopleService from "../../services/PeopleService";

function PersonWorks({personId}) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [works, setWorks] = useState([])
    useEffect(() => {
        function loadPersonWorks(id) {
            PeopleService.getWorks(id)
                .then(p => {
                    setIsLoaded(true)
                    setWorks(p.data)
                })
                .catch(err => setError(err))
        }

        loadPersonWorks(personId);
    }, [personId])

    if (error) {
        if (error.message === "404") {
            return <NotFound/>
        }
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        if (works && works.length > 0) {
            return(
                <div className={styles.personWorksBlock}>
                    <PersonItemsHeader title="properties.bibliography" />
                    {works.map(work => (
                        <PersonWork key={work.id} work={work} />
                    ))}
                </div>
            )
        }
    }
}

export default PersonWorks
