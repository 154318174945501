import styles from "./css/Publisher.module.css"
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import PublisherService from "../../services/PublisherService";
import NotFound from "../error/NotFound";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";
import {FormattedMessage} from "react-intl";

function Publisher() {
    const {publisherId} = useParams()
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [publisher, setPublisher] = useState(null)
    useEffect(() => {
        PublisherService.getById(publisherId)
            .then(p => p.data)
            .then(p => {
                setIsLoaded(true)
                setPublisher(p)
            })
            .catch(err => {
                setIsLoaded(true);
                setError(err);
            })
    }, [publisherId])
    if (error) {
        if (error.message === "404") {
            return <NotFound/>
        }
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        return <div className={styles.publisherBlock}>
            <div>
                <h2>
                    {publisher.name}
                </h2>
            </div>
            <div>
                <small>
                    <FormattedMessage id="countries.UA" />
                    , {publisher.city}
                </small>
            </div>
            <div className={styles.authorLinks}>
                <div className={styles.title}>
                    <strong>
                        Автори:
                    </strong>
                </div>
                {publisher.authors.map(author => (
                    <div className={styles.authorLink} key={author.id}>
                        <a href={`/people/${author.id}`}>
                            {author.name}
                        </a>
                    </div>
                ))}
            </div>
        </div>
    }
}

export default Publisher
