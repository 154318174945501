import axios from "axios";
import AuthService from "./AuthService";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
        "Content-Type": "application/json",
    },
});
const authClient = axios.create({
    baseURL: process.env.REACT_APP_AUTH_API_HOST,
    headers: {
        "Content-Type": "application/json",
    },
});

apiClient.interceptors.request.use(
    (config) => {
        const token = AuthService.getToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (err.response && err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            try {
                const rs = await authClient.post("/refresh", AuthService.getRefreshToken());

                AuthService.saveToken(rs);

                return apiClient(originalConfig);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }

        return Promise.reject(err);
    }
);

export {apiClient, authClient};
