import styles from "./css/CoverArt.module.css";
import {FormattedMessage} from "react-intl";
import CommaSeparatedPeopleList from "../people/CommaSeparatedPeopleList";

function CoverArt({artists}) {
    if (artists && artists.length > 0) {
        return(
            <div className={styles.bookArtists}>
                <p>
                    <FormattedMessage id="prefix.cover.art"/>
                    &nbsp;
                    <CommaSeparatedPeopleList people={artists} name={it => it.conjugatedName}/>
                </p>
            </div>
        )
    }
}

export default CoverArt
