import {apiClient} from "./Api";

class LibraryService {
    create(library) {
        return apiClient.post(process.env.REACT_APP_API_HOST + "/libraries", library);
    }

    addBook(libraryId, bookId) {
        return apiClient.post(process.env.REACT_APP_API_HOST + "/libraries/" + libraryId + "/books/" + bookId);
    }

    removeBook(libraryId, bookId) {
        return apiClient.delete(process.env.REACT_APP_API_HOST + "/libraries/" + libraryId + "/books/" + bookId);
    }

    list() {
        return apiClient.get(process.env.REACT_APP_API_HOST + "/libraries");
    }

    listForBook(bookId) {
        return apiClient.get(process.env.REACT_APP_API_HOST + "/books/" + bookId + "/libraries/");
    }

    getById(id, page, size) {
        const url = new URL(process.env.REACT_APP_API_HOST + "/libraries/" + id);

        if (page) {
            url.searchParams.append("page", page)
        }

        if (size) {
            url.searchParams.append("size", size)
        }

        return apiClient.get(url.toString());
    }
}

export default new LibraryService()
