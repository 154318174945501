function PersonName({name, originalName}) {
    return(
        <>
            <h1 itemProp="name">{name}</h1>
            <OriginalName name={name} originalName={originalName} />
        </>
    )
}

function OriginalName({name, originalName}) {
    if (originalName && originalName !== name) {
        return(
            <h3>
                {originalName}
            </h3>
        )
    }
}

export default PersonName
