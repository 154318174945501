import {useEffect, useState} from "react";

import {useIntl} from "react-intl";
import ErrorBlock from "../error/ErrorBlock";
import AuthService from "../../services/AuthService";
import {Navigate, useSearchParams} from "react-router-dom";
import Loading from "../error/Loading";

function Activation() {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState(null);
    const [isActivated, setIsActivated] = useState(false);
    const [isUnauthorized, setIsUnauthorized] = useState(false);
    const intl = useIntl()
    useEffect(() => {
        document.title = "Lectorem: " + intl.formatMessage({id: "labels.activation"});
        AuthService.activate(searchParams.get('id')).then(
            () => {
                setIsActivated(true)
            },
            (err) => {
                if (err.response) {
                    if (err.response.status === 401) {
                        setIsUnauthorized(true)
                    }
                    setError(err.response.body)
                } else {
                    setError(err)
                }
            }
        )
    }, [intl, searchParams])

    if (isActivated) {
        return <Navigate to="/" />
    } else if(isUnauthorized) {
        return <Navigate to="/login" />
    } else if(error) {
        console.error(error.message)
        return <ErrorBlock/>;
    } else {
        return <Loading/>;
    }
}

export default Activation
