import {useParams} from "react-router-dom";
import {useState, useEffect} from "react";
import AddPerson from "../people/AddPerson";
import ErrorBlock from "../error/ErrorBlock";
import IssueService from "../../services/IssueService";
import Loading from "../error/Loading";
import FeedbackView from "../feedback/FeedbackView";

function Issue() {
    const {issueId} = useParams();
    const [issue, setIssue] = useState(null)
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {
        IssueService.getById(issueId)
            .then(p => {
                setIsLoaded(true)
                setIssue(p.data)
            })
            .catch(err => setError(err))
    }, [issueId])
    if (error) {
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        switch (issue.type) {
            case "PERSON_DRAFT":
                return <AddPerson personDraft={issue.person}/>
            case "FEEDBACK":
                return <FeedbackView feedback={issue.feedback} isClosed={issue.status === "SPAM" || issue.status === "APPROVED"} />
            default:
                return <ErrorBlock/>
        }
    }
}

export default Issue
