import {useState, useEffect} from 'react';

import styles from "./css/Home.module.css"

import HorizontalGallery from "../gallery/HorizontalGallery";
import Banner from "../banner/Banner";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";

function Home() {
    const itemTypes = {
        book: {
            id: "book",
            path: "/books",
            schema: "https://schema.org/Book"
        },
        person: {
            id: "person",
            path: "/people",
            schema: "https://schema.org/Person"
        }
    };
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [books, setBooks] = useState([]);
    const [people, setPeople] = useState([]);

    function loadNewBooks() {
        fetch(process.env.REACT_APP_API_HOST + "/books/new")
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setBooks(result);
                },
                (err) => {
                    setIsLoaded(true);
                    setError(err);
                }
            )
    }

    function loadNewPeople() {
        fetch(process.env.REACT_APP_API_HOST + "/people/new")
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setPeople(result);
                },
                (err) => {
                    setIsLoaded(true);
                    setError(err);
                }
            )
    }

    useEffect(() => {
        document.title = "Lectorem";
        loadNewBooks();
        loadNewPeople();
    }, [])

    if (error) {
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        return (
            <>
                <div className={styles.homeContainer}>
                    <Banner />
                    <HorizontalGallery
                        items={books}
                        type={itemTypes.book}
                        title="labels.new.books"
                    />
                    <HorizontalGallery
                        items={people}
                        type={itemTypes.person}
                        title="labels.new.people"
                    />
                </div>
            </>
        )
    }
}

export default Home
