import styles from "./css/Autocomplete.module.css";
import {useEffect, useState} from "react";
import LanguageService from "../../services/LanguageService";

function LanguageSelector({updater}) {
    useEffect(() => {
        LanguageService.languages()
            .then(res => setLangs(res.data))
    }, [])
    const [langs, setLangs]  = useState([])
    const [filteredLangs, setFilteredLangs] = useState([])
    const [language, setLanguage] = useState('');
    function handleChange(event) {
        let value = event.target.value;
        if (!value || value.length === 0) {
            setFilteredLangs([])
        } else {
            setFilteredLangs(langs.filter(i => i.name.toLowerCase().startsWith(value.toLowerCase())))
        }
        setLanguage(value);
    }
    function handleOnClick(item) {
        updater(item.id)
        setLanguage(item.name)
        setFilteredLangs([])
    }
    return(
        <div className={styles.autocomplete}>
            <input type="text" id="language" name="language" required
                   value={language} onChange={handleChange} />
            <div id="lang-autocomplete-list" className={styles.autocompleteItems}>
                {filteredLangs.map(lang => (
                    <div key={lang.id} onClick={() => handleOnClick(lang)}>
                        {lang.name}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default LanguageSelector
