import {FormattedMessage} from "react-intl";

function Pages({pages}) {
    if (pages) {
        return(
            <span itemProp="numberOfPages">
                , {pages} <FormattedMessage id="suffixes.pages"/>
            </span>
        )
    }
}

export default Pages
