import styles from "./css/Error.module.css"

import {FormattedMessage} from "react-intl";

const ErrorBlock = () => {
    return (
        <div className={styles.errorContainer}>
            <div className={styles.error}>
                <h1>
                    <FormattedMessage id="errors.something.went.wrong" />
                </h1>
                <div>
                    <a href="/">
                        <FormattedMessage id="links.home" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ErrorBlock
