import styles from "./css/WorkBooks.module.css";
import {FormattedMessage} from "react-intl";
import WorkBook from "./WorkBook";

function WorkBooks({books}) {
    if (books && books.length > 0) {
        return(
            <div className={styles.booksBlock}>
                <div className={styles.workBookTitleRow}>
                    <p className={styles.workBooksBlockHeader}>
                        <FormattedMessage id="labels.books" />
                    </p>
                </div>
                {books.map(book => (
                    <WorkBook key={book.id} book={book} />
                ))}
            </div>
        )
    }
}

export default WorkBooks
