import {useEffect} from "react";

import styles from "./css/About.module.css"

import {FormattedMessage, useIntl} from "react-intl";

function About() {
    const intl = useIntl()
    useEffect(() => {
        document.title = "Lectorem: " + intl.formatMessage({id: "menus.about"});
    }, [intl])

    return <div>
        <div className={styles.title}>
            <h1>
                <FormattedMessage id="labels.about.title" />
                <span>Q&A</span>
            </h1>
        </div>
        <ul className={styles.qa}>
            <li className={styles.q}>
                <FormattedMessage id="labels.about.q1" />
            </li>
            <li className={styles.a}>
                <FormattedMessage id="labels.about.a1" />
            </li>
            <li className={styles.q}>
                <FormattedMessage id="labels.about.q2" />
            </li>
            <li className={styles.a}>
                <FormattedMessage id="labels.about.a2" />
            </li>
            <li className={styles.q}>
                <FormattedMessage id="labels.about.q3" />
            </li>
            <li className={styles.a}>
                <FormattedMessage id="labels.about.a3.start" />
                <ul>
                    <li><FormattedMessage id="labels.about.a3.point1" /></li>
                    <li><FormattedMessage id="labels.about.a3.point2" /></li>
                    <li><FormattedMessage id="labels.about.a3.point3" /></li>
                </ul>
                <p><FormattedMessage id="labels.about.a3.end" /></p>
            </li>
        </ul>
    </div>
}

export default About
