import {useParams} from "react-router-dom";

import styles from "./css/Person.module.css"
import {useState, useEffect} from "react";
import PersonPhoto from "./PersonPhoto";
import PersonProperties from "./PersonProperties";
import PersonWorks from "./PersonWorks";
import PersonTranslations from "./PersonTranslations";
import NotFound from "../error/NotFound";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";
import {FormattedMessage} from "react-intl";
import AuthService from "../../services/AuthService";

function Person() {
    const {personId} = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [person, setPerson] = useState(null)
    useEffect(() => {
        function loadPerson(id) {
            fetch(`${process.env.REACT_APP_API_HOST}/people/${id}`)
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                    throw new Error(res.status.toString())
                })
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setPerson(result);
                        document.title = "Lectorem: " + result.name;
                    },
                    (err) => {
                        setIsLoaded(true);
                        setError(err);
                    }
                )
        }

        loadPerson(personId);
    }, [personId])
    if (error) {
        if (error.message === "404") {
            return <NotFound/>
        }
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        return(
            <div itemScope itemType="https://schema.org/Person" className={styles.personBlocks}>
                <div className={styles.personMainBlock}>
                    <PersonPhoto id={person.id} name={person.name} />
                    <PersonProperties properties={person} />
                </div>
                <PersonWorks personId={personId} />
                <PersonTranslations personId={personId} />
                {AuthService.isAdmin() &&
                    <div className="link indent">
                        <a href={`/people/${personId}/works/add`} >
                            <FormattedMessage id="links.work.add" />
                        </a>
                    </div>
                }
            </div>
        )
    }
}

export default Person
