import {apiClient} from "./Api";

class MovieService {
    create(workId, movie) {
        return apiClient.post(process.env.REACT_APP_API_HOST + "/works/" + workId + "/movies", movie);
    }

    getByWorkId(workId) {
        return apiClient.get(process.env.REACT_APP_API_HOST + "/works/" + workId + "/movies")
    }
}

export default new MovieService()
