import styles from "./css/LibrariesButtons.module.css";
import {useIntl} from "react-intl";
import LibraryService from "../../services/LibraryService";
import {useEffect, useState} from "react";

const MyLibraryButton = ({bookId, library}) => {
    const intl = useIntl()
    const [inLibrary, setInLibrary] = useState(false)
    useEffect(() => {
        if (library !== undefined) {
            if (library.inLibrary) {
                setInLibrary(true)
            }
        }
    }, [library]);
    const handleOnClick = () => {
        if (inLibrary) {
            LibraryService.removeBook(library.library.id, bookId)
                .then(() => setInLibrary(false))
        } else {
            if (library === undefined) {
                LibraryService.create(
                    {
                        name: intl.formatMessage({id: "links.book.add.to.my.library"}),
                        type: "OWN",
                        accessType: "PUBLIC"
                    })
                    .then(r => r.data)
                    .then(libraryId => LibraryService.addBook(libraryId, bookId))
                    .then(() => setInLibrary(true))
            } else {
                LibraryService.addBook(library.library.id, bookId)
                    .then(() => setInLibrary(true))
            }
        }
    }
    return (
        <div onClick={handleOnClick} title={intl.formatMessage({id: "tooltip.libraries.own.add"})}
             className={styles.librariesButton}>
            <svg className={`${styles.myLibrary} ${inLibrary ? styles.active : styles.inactive}`} viewBox="0 0 297 297">
                <path d="M271.519,297c5.546,0,10.043-4.497,10.043-10.043V10.042C281.562,4.496,277.065,0,271.519,0H25.481
                        c-5.546,0-10.043,4.496-10.043,10.042v276.915c0,5.546,4.497,10.043,10.043,10.043H271.519z M230.724,184.607h-10.672v-41.45h10.672
                        V184.607z M261.477,112.387v72.221h-10.668v-51.492c0-5.547-4.497-10.043-10.042-10.043H210.01c-5.546,0-10.043,4.496-10.043,10.043
                        v51.492h-23.228l12.259-49.059c1.345-5.38-1.928-10.833-7.31-12.178c-5.374-1.337-10.831,1.928-12.177,7.31l-13.476,53.927h-10.054
                        l12.26-49.058c1.346-5.381-1.927-10.833-7.308-12.177c-5.382-1.345-10.832,1.926-12.177,7.307l-13.476,53.928H35.523v-72.221
                        H261.477z M76.948,92.302H66.276V50.853h10.672V92.302z M261.477,20.084v72.218h-89.758l-13.477-53.927
                        c-1.346-5.381-6.8-8.657-12.178-7.307c-5.38,1.344-8.652,6.796-7.307,12.177l12.26,49.057h-10.054L127.49,38.376
                        c-1.346-5.382-6.8-8.651-12.177-7.309c-5.382,1.345-8.653,6.796-7.31,12.177l12.257,49.058H97.033V40.811
                        c0-5.546-4.497-10.043-10.043-10.043H56.233c-5.546,0-10.042,4.497-10.042,10.043v51.491H35.523V20.084H261.477z M35.523,245.5
                        l7.85,31.415h-7.85V245.5z M92.324,276.915h-10.67v-41.452h10.67V276.915z M148.401,276.915h-10.672v-41.452h10.672V276.915z
                         M261.477,204.692v72.223h-92.991V225.42c0-5.546-4.496-10.042-10.042-10.042h-30.756c-5.546,0-10.042,4.496-10.042,10.042v51.495
                        h-5.236V225.42c0-5.546-4.496-10.042-10.042-10.042H71.612c-5.546,0-10.043,4.496-10.043,10.042v41.465l-10.969-43.898
                        c-1.346-5.383-6.801-8.656-12.178-7.309c-1.055,0.264-2.025,0.689-2.9,1.237v-12.222H261.477z"/>
            </svg>
        </div>
    )
}

export default MyLibraryButton
