import styles from "../form/css/AddItem.module.css"
import {FormattedMessage, useIntl} from "react-intl";
import {useState} from "react";
import AuthService from "../../services/AuthService";
import ErrorBlock from "../error/ErrorBlock";
import {Navigate, useParams} from "react-router-dom";
import MovieService from "../../services/MovieService";

function AddMovie() {
    const intl = useIntl()

    const {workId} = useParams();
    const [id, setId] = useState(null)
    const [error, setError] = useState(null)
    const [movie, setMovie] = useState({
        workId: workId,
        title: "",
        year: null,
        kinoriumId: null
    })

    function onSubmit() {
        return (e) => {
            e.preventDefault();
            MovieService.create(workId, movie)
                .then(p => setId(p.data))
                .catch(err => setError(err))
        };
    }

    if (AuthService.isAdmin()) {

    }
    if (id) {
        return <Navigate to={`/works/${workId}`} />
    } else if (error) {
        console.error(error)
        return <ErrorBlock />
    } else {
        return(
            <div className={styles.formContainer}>
                <form onSubmit={onSubmit()} >
                    <div className={styles.addItemContainer}>
                        <div className={styles.inputGroup}>
                            <div className={styles.row}>
                                <div className={`${styles.inputGroup} ${styles.mainInRow}`}>
                                    <label htmlFor="title">
                                        <FormattedMessage id="properties.title" />
                                    </label>
                                    <input type="text" id="title" name="title" required
                                           onChange={(e) => setMovie({...movie, title: e.target.value})}
                                           value={movie.title} />
                                </div>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="year">
                                        <FormattedMessage id="properties.year"/>
                                    </label>
                                    <input className={styles.number} name="year" id="year" pattern="[0-9]{4}"
                                           onChange={(e) => setMovie({...movie, year: e.target.value})}
                                    />
                                </div>
                                <div className={styles.inputGroup}>
                                    <label htmlFor="kinoriumId">Kinorium ID</label>
                                    <input className={styles.number} name="kinoriumId" id="kinoriumId"
                                           onChange={(e) => setMovie({...movie, kinoriumId: e.target.value})}
                                    />
                                </div>
                            </div>
                        </div>
                        <input type="submit" value={intl.formatMessage({id: "properties.submit"})} />
                    </div>
                </form>
            </div>
        )
    }
}

export default AddMovie
