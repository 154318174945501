import styles from "./css/PersonLabels.module.css";
import {FormattedMessage} from "react-intl";

function PersonLabels({roles}) {
    return(
        <div className={styles.personLabels}>
            {roles.includes("WRITER") &&
                <div itemProp="jobTitle" className={styles.writerLabel}>
                    <FormattedMessage id="labels.writer" />
                </div>
            }
            {roles.includes("TRANSLATOR") &&
                <div itemProp="jobTitle" className={styles.translatorLabel}>
                    <FormattedMessage id="labels.translator" />
                </div>
            }
            {roles.includes("ARTIST") &&
                <div itemProp="jobTitle" className={styles.artistLabel}>
                    <FormattedMessage id="labels.artist" />
                </div>
            }
        </div>
    )
}

export default PersonLabels
