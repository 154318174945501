import React, {createContext, useReducer} from "react";
import AuthService from "../services/AuthService";

function init(isLoggedIn) {
    return {isLoggedIn: isLoggedIn};
}

function reducer(state, action) {
    switch (action.type) {
        case 'LOGIN':
            return {
                isLoggedIn: true
            };
        case 'LOGOUT':
            return {
                isLoggedIn: false
            };
        default:
            throw new Error();
    }
}

const UserStore = ({children}) => {
    const [state, dispatch] = useReducer(reducer, AuthService.getCurrentUser(), init);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(init(false));
export default UserStore;
