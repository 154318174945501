import styles from "./css/Mark.module.css"
import {useContext, useState} from "react";
import Star from "./Star";
import {Context} from "../../stores/UserStore";

const Mark = ({workId, currentValue, setRating}) => {
    const [state] = useContext(Context);
    const [mark, setMark] = useState(currentValue)

    if (state.isLoggedIn) {
        return <div className={styles.starRating}>
            <Star workId={workId} mark={mark} setMark={setMark} setRating={setRating} position="5" />
            <Star workId={workId} mark={mark} setMark={setMark} setRating={setRating} position="4" />
            <Star workId={workId} mark={mark} setMark={setMark} setRating={setRating} position="3" />
            <Star workId={workId} mark={mark} setMark={setMark} setRating={setRating} position="2" />
            <Star workId={workId} mark={mark} setMark={setMark} setRating={setRating} position="1" />
        </div>
    }
}

export default Mark
