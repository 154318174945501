import AuthService from "../../services/AuthService";
import ErrorBlock from "../error/ErrorBlock";

function Admin() {
    if (AuthService.isAdmin()) {
        return(
            <></>
        )
    } else {
        return <ErrorBlock />
    }

}

export default Admin
