import styles from "../form/css/AddItem.module.css"
import {FormattedMessage, useIntl} from "react-intl";
import {useState} from "react";
import LanguageSelector from "../form/LanguageSelector";
import SearchableInput from "../form/SearchableInput";
import AuthService from "../../services/AuthService";
import ErrorBlock from "../error/ErrorBlock";
import {Navigate, useParams} from "react-router-dom";
import TranslationService from "../../services/TranslationService";

function AddTranslation() {
    const intl = useIntl()

    const {workId} = useParams();
    const [id ,setId] = useState(null)
    const [error, setError] = useState(null)
    const [translation, setTranslation] = useState({
        workId: workId,
        title: "",
        languageFrom: "",
        languageTo: "ukr",
        translators: []
    })

    function onSubmit() {
        return (e) => {
            e.preventDefault();
            TranslationService.create(translation)
                .then(p => setId(p.data))
                .catch(err => setError(err))
        };
    }

    if (id) {
        if (AuthService.isAdmin()) {
            return <Navigate to={`/translations/${id}`} />
        } else {
            return <Navigate to={`/issues`} />
        }
    } else if (error) {
        console.error(error)
        return <ErrorBlock />
    } else {
        if (AuthService.getCurrentUser()) {
            return(
                <div className={styles.formContainer}>
                    <form onSubmit={onSubmit()} >
                        <div className={styles.addItemContainer}>
                            <div className={styles.inputGroup}>
                                <SearchableInput updater={it => setTranslation({...translation, translators: it})} type="person" />
                                <div className={styles.row}>
                                    <div className={`${styles.inputGroup} ${styles.mainInRow}`}>
                                        <label htmlFor="title">
                                            <FormattedMessage id="properties.title" />
                                        </label>
                                        <input type="text" id="title" name="title" required
                                               onChange={(e) => setTranslation({...translation, title: e.target.value})}
                                               value={translation.title} />
                                    </div>
                                    <div className={styles.inputGroup}>
                                        <label htmlFor="language">
                                            <FormattedMessage id="properties.translationFrom" />
                                        </label>
                                        <LanguageSelector updater={it => setTranslation({...translation, languageFrom: it})} />
                                    </div>
                                    <div className={styles.inputGroup}>
                                        <label htmlFor="languageTo">
                                            <FormattedMessage id="properties.translationTo" />
                                        </label>
                                        <input type="text" id="languageTo" name="languageTo" required
                                               value="Ukrainian" disabled={true} />
                                    </div>
                                </div>
                            </div>
                            <input type="submit" value={intl.formatMessage({id: "properties.submit"})} />
                        </div>
                    </form>
                </div>
            )
        } else {
            return <ErrorBlock />
        }
    }
}

export default AddTranslation
