import styles from "./css/Profile.module.css"
import AuthService from "../../services/AuthService";
import {FormattedMessage} from "react-intl";

const Profile = () => {
    const userInfo = AuthService.getCurrentUser()

    if (userInfo) {
        return <div className={styles.userLinks}>
            <div className="link indent">
                <a href={`/issues`}>
                    <FormattedMessage id="links.profile.issues"/>
                </a>
            </div>
            <div className="link indent">
                <a href={`/libraries`}>
                    <FormattedMessage id="links.profile.libraries"/>
                </a>
            </div>
            <div className="link indent">
                <a href={`/marks`}>
                    <FormattedMessage id="links.profile.marks"/>
                </a>
            </div>
            <div className="link indent">
                <a href={`/reviews`}>
                    <FormattedMessage id="links.profile.reviews"/>
                </a>
            </div>
        </div>
    }
}

export default Profile
