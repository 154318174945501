import styles from "./css/Banner.module.css";

function Banner() {
    return <div className={styles.bannerContainer}>
        <a href="https://war.ukraine.ua/donate/" target="_blank" rel="noopener noreferrer">
            <img src={process.env.REACT_APP_IMAGE_HOST + "/banner.webp"} alt="Donate"/>
        </a>
    </div>;
}

export default Banner
