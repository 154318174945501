import styles from "./css/WorkReviews.module.css";
import {FormattedMessage} from "react-intl";
import {useEffect, useState} from "react";
import AddReview from "./AddReview";
import Review from "./Review";
import ReviewService from "../../services/ReviewService";
import NotFound from "../error/NotFound";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";

function WorkReviews({workId}) {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [reviews, setReviews] = useState([])

    useEffect(() => {
        const getReviews = (workId) => {
            ReviewService.getByWorksId(workId)
                .then(res =>  res.data)
                .then(result => {
                        setIsLoaded(true);
                        setReviews(result);
                })
                .catch(err => setError(err))
        }

        getReviews(workId)
    }, [workId])
    if (error) {
        if (error.message === "404") {
            return <NotFound/>
        }
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        return(
            <div className={styles.reviewsBlock}>
                <div className={styles.workReviewsTitleRow}>
                    <p className={styles.workReviewsBlockHeader}>
                        <FormattedMessage id="labels.reviews" />
                    </p>
                </div>
                <div className={styles.reviews}>
                    {reviews.map(review =>
                        <Review key={review.author.username} review={review} />
                    )}
                    <AddReview worksId={workId} />
                </div>
            </div>
        )
    }
}

export default WorkReviews
