import styles from "./css/WorkTranslations.module.css"
import TranslationDetails from "./TranslationDetails";

function WorkTranslations({translations}) {

    if (translations && translations.length > 0) {
        return (<div className={styles.workTranslations} >
            {translations.map(translation => (
                <div key={translation.id}>
                    <a href={`/translations/${translation.id}`}>
                        «{translation.title}»
                    </a>
                    <span>, </span>
                    <TranslationDetails from={translation.from} translators={translation.translators} />
                </div>
            ))}
        </div>)
    }
}

export default WorkTranslations
