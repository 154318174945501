import {Navigate} from "react-router-dom";
import {useEffect, useContext} from "react";
import AuthService from "../../services/AuthService";
import {Context} from "../../stores/UserStore";

function Logout() {
    const [, dispatch] = useContext(Context);
    useEffect(() => {
        AuthService.logout()
        dispatch({type: 'LOGOUT'})
    }, [dispatch])
    return <Navigate to="/" />
}

export default Logout
