import {useState, useEffect} from "react";

import {FormattedMessage, useIntl} from "react-intl";
import styles from "../form/css/Form.module.css"
import ErrorBlock from "../error/ErrorBlock";

function Feedback() {
    const intl = useIntl()
    useEffect(() => {
        document.title = "Lectorem: " + intl.formatMessage({id: "menus.feedback"});
    }, [intl])
    const [isSaved, setIsSaved] = useState(false)
    const [error, setError] = useState(null)
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        };
        fetch(`${process.env.REACT_APP_API_HOST}/feedbacks`, requestOptions)
            .then(response => response.json())
            .then(
                (result) => {
                    setIsSaved(true);
                    console.debug(`Feedback with id ${result} was saved`)
                },
                (err) => {
                    setIsSaved(false);
                    setError(err);
                })
    }
    if (isSaved) {
        return(
            <div className={styles.formContainer}>
                <h3>
                    <FormattedMessage id="labels.feedback.accepted" />
                </h3>
            </div>
        )
    } else if(error) {
        console.error(error.message)
        return <ErrorBlock/>;
    } else {
        return(
            <div className={styles.formContainer}>
                <form onSubmit={handleSubmit}>
                    <label>
                        <FormattedMessage id="properties.name" />
                        <input className={styles.formInput}
                               value={formData.name}
                               onChange={(e) => setFormData({...formData, name: e.target.value})}
                               type="text"
                               name="name"
                               id="name"
                               required />
                    </label>
                    <label>
                        <FormattedMessage id="properties.email" />
                        <input className={styles.formInput}
                               value={formData.email}
                               onChange={(e) => setFormData({...formData, email: e.target.value})}
                               type="email"
                               name="email"
                               id="email"
                               required />
                    </label>
                    <label>
                        <FormattedMessage id="properties.message" />
                        <textarea className={styles.formTextarea}
                                  value={formData.message}
                                  onChange={(e) => setFormData({...formData, message: e.target.value})}
                                  name="message"
                                  id="message"
                                  required />
                    </label>
                    <input type="submit" value={intl.formatMessage({id: "properties.submit"})} />
                </form>
            </div>
        )
    }
}

export default Feedback
