import styles from "./css/Pagination.module.css";

const Pagination = ({baseUrl, totalPages, page, size}) => {
    const pages = [];
    const firstPage = Math.max(page - 3,  0);
    const lastPage = Math.min(page + 3, totalPages - 1);
    for (let i = firstPage; i <= lastPage; i++) {
        pages[i] = i;
    }
    if (totalPages > 1) {
        return (
            <div className={styles.pagination}>
                {firstPage > 0 &&
                    <a href={`${baseUrl}?page=0&size=${size}`}>
                        &lt;
                    </a>
                }
                {pages.map(p => (
                    <a key={p}
                       href={`${baseUrl}?page=${p}&size=${size}`}
                       className={page === p ? styles.active : null}>
                        {p + 1}
                    </a>
                ))}
                {lastPage < totalPages - 1 &&
                    <a href={`${baseUrl}?page=${totalPages - 1}&size=${size}`}>
                        &gt;
                    </a>
                }
            </div>
        )
    }
};

export default Pagination
