import {FormattedMessage} from "react-intl";
import LibraryService from "../../services/LibraryService";
import {useState} from "react";

const BookLibrariesList = ({expandList, libraries, bookId}) => {
    const [libs, setLibs] = useState(libraries)
    const handleAddOrRemove = (e, lib, index) => {
        e.preventDefault()
        let newArr = [...libs];
        if (lib.inLibrary) {
            LibraryService.removeBook(lib.library.id, bookId)
            lib.inLibrary = false
        } else {
            LibraryService.addBook(lib.library.id, bookId)
            lib.inLibrary = true
        }
        newArr[index] = lib;
        setLibs(newArr)
    };

    if (expandList) {
        return (
            <>
                <div className="break hr"/>
                <ul className="no-bullets">
                    {libs.map((lib, index) => (
                        <li key={lib.library.id} className="link">
                            <a href={`/libraries/${lib.library.id}`}>{lib.library.name}</a>&nbsp;
                            <a href="/" onClick={e => handleAddOrRemove(e, lib, index)}>
                                <span>{lib.inLibrary ? "➖" : "➕"}</span>
                            </a>
                        </li>
                    ))}
                    <li className="link indent">
                        <a href="/libraries/add">
                            <FormattedMessage id="links.library.add"/>
                        </a>
                    </li>
                </ul>
            </>
        )
    }
}

export default BookLibrariesList
