function Isbn({isbn}) {
    if (isbn) {
        return(
            <p>
                <span itemProp="isbn">
                    {`ISBN: ${isbn}`}
                </span>
            </p>
        )
    }
}

export default Isbn
