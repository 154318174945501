import {apiClient} from "./Api";

class PeopleService {
    create(person) {
        return apiClient.post(process.env.REACT_APP_API_HOST + "/people", person);
    }

    getById(id) {
        return apiClient.get(process.env.REACT_APP_API_HOST + "/people/" + id)
    }

    getWorks(id) {
        return apiClient.get(process.env.REACT_APP_API_HOST + "/people/" + id + "/works")
    }
}

export default new PeopleService()
