import styles from "./css/OneLetterPeopleItem.module.css";

import {FormattedMessage} from "react-intl";

function OneLetterPeopleItem(props) {
    return (
        <div itemScope itemType="https://schema.org/Person">
            <div className={styles.labels}>
                {props.person.roles.includes("WRITER") &&
                    <div itemProp="jobTitle" className={styles.label + " " + styles.labelWriter}>
                        <FormattedMessage id="labels.writer" />
                    </div>
                }
                {props.person.roles.includes("TRANSLATOR") &&
                    <div itemProp="jobTitle" className={styles.label + " " + styles.labelTranslator}>
                        <FormattedMessage id="labels.translator" />
                    </div>
                }
                {props.person.roles.includes("ARTIST") &&
                    <div itemProp="jobTitle" className={styles.label + " " + styles.labelArtist}>
                        <FormattedMessage id="labels.artist" />
                    </div>
                }
            </div>
            <a href={`/people/${props.person.id}`}>
                <img src={`${process.env.REACT_APP_IMAGE_HOST}/people/${props.person.id}.webp`} alt={props.person.name}/>
            </a>
            <p itemProp="name">
                {props.person.name}
            </p>
        </div>
    )
}

export default OneLetterPeopleItem
