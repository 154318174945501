import styles from "../form/css/AddItem.module.css"
import {FormattedMessage, useIntl} from "react-intl";
import {useState} from "react";
import {Navigate} from "react-router-dom";
import ErrorBlock from "../error/ErrorBlock";
import LibraryService from "../../services/LibraryService";

function AddLibrary() {
    const intl = useIntl()

    const [id ,setId] = useState(null)
    const [error, setError] = useState(null)
    const [library, setLibrary] = useState({
        name: intl.formatMessage({id: "default.title"}),
        type: "OTHER",
        accessType: "PUBLIC"
    })

    function onSubmit() {
        return (e) => {
            e.preventDefault();
            LibraryService.create(library)
                .then(p => setId(p.data))
                .catch(err => setError(err))
        };
    }

    if (id) {
        return <Navigate to={`/libraries/${id}`} />
    } else if (error) {
        console.error(error)
        return <ErrorBlock />
    } else {
        return (
            <div className={styles.formContainer}>
                <form onSubmit={onSubmit()}>
                    <div className={styles.addItemContainer}>
                        <div className={styles.inputGroup}>
                            <label htmlFor="title">
                                <FormattedMessage id="properties.title"/>
                            </label>
                            <input type="text" id="title" name="title"
                                   onChange={(e) => setLibrary({...library, name: e.target.value})}
                                   required/>
                        </div>
                        <input type="submit" value={intl.formatMessage({id: "properties.submit"})}/>
                    </div>
                </form>
            </div>
        )
    }
}

export default AddLibrary
