import {useEffect, useState} from "react";
import MovieService from "../../services/MovieService";
import styles from "./css/WorkMovies.module.css"
import {FormattedMessage} from "react-intl";

const WorkMovies = ({workId}) => {
    const [movies, setMovies] = useState([])
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        MovieService.getByWorkId(workId)
            .then(p => p.data)
            .then(p => setMovies(p))
            .finally(() => setIsLoaded(true))
    }, [workId])

    if (isLoaded && movies.length > 0) {
        return (
            <div className={styles.workMoviesBlock}>
                <h5 className={styles.workMovieBlockHeader}>
                    <FormattedMessage id="labels.movies" />
                </h5>
                <ul>
                    {movies.map(movie => (
                        <li key={movie.id} className={styles.workMovie}>
                            <a href={`https://ua.kinorium.com/${movie.kinoriumId}`} target="_blank" rel="noreferrer" referrerPolicy="origin">{movie.title} ({movie.year})</a>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}

export default WorkMovies
