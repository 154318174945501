import {apiClient} from "./Api";

class WorkService {
    create(work) {
        return apiClient.post(process.env.REACT_APP_API_HOST + "/works", work);
    }

    getById(id) {
        return apiClient.get(process.env.REACT_APP_API_HOST + "/works/" + id)
    }

    rate(workId, mark) {
        return apiClient.post(process.env.REACT_APP_API_HOST + "/works/" + workId + "/marks/" + mark)
    }

    unrate(workId) {
        return apiClient.delete(process.env.REACT_APP_API_HOST + "/works/" + workId + "/marks")
    }
}

export default new WorkService()
