import styles from "./css/Gallery.module.css";

import HorizontalGalleryItem from "./HorizontalGalleryItem";

import {FormattedMessage} from "react-intl";

function HorizontalGallery(props) {
    return (
        <div className={styles.gallery}>
            <h3>
                <FormattedMessage id={props.title}/>
            </h3>
            <div className={styles.galleryBody}>
                {props.items.map(item => (
                    <HorizontalGalleryItem key={item.id} item={item} type={props.type}/>
                ))}
            </div>
        </div>
    )
}

export default HorizontalGallery
