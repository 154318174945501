import styles from './css/Issues.module.css'
import {useState, useEffect} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import IssueService from "../../services/IssueService";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";
import AuthService from "../../services/AuthService";

function Issues() {
    const intl = useIntl()

    useEffect(() => {
        IssueService.list()
            .then(p => {
                setIsLoaded(true)
                setIssues(p.data)
            })
            .catch(err => setError(err))

        document.title = "Lectorem: " + intl.formatMessage({id: "issue.title"});
    }, [intl])
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false);
    const [issues, setIssues] = useState([]);
    if (error) {
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        return (
            <div className={styles.issues}>
                <table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>
                            <FormattedMessage id="issue.header.title" />
                        </th>
                        <th>
                            <FormattedMessage id="issue.header.type" />
                        </th>
                        <th>
                            <FormattedMessage id="issue.header.status" />
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {issues.map(issue => [
                        <tr key={issue.id}>
                            <td>{issue.id}</td>
                            <td>
                                {AuthService.isAdmin() &&
                                    <a href={`/issues/${issue.id}`}>
                                        {issue.title}
                                    </a>
                                }

                                {!AuthService.isAdmin() &&
                                    issue.title
                                }
                            </td>
                            <td>
                                <FormattedMessage id={`issue.type.${issue.type}`} />
                            </td>
                            <td>
                                <FormattedMessage id={`issue.status.${issue.status}`} />
                            </td>
                        </tr>
                    ])}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Issues
