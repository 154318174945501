import styles from "./css/PersonWork.module.css";
import BookLink from "../book/BookLink";
import CommaSeparatedPeopleList from "./CommaSeparatedPeopleList";

function PersonTranslation({translation}) {
    const defaultTitle = translation.title ? translation.title : translation.work.title
    return(
        <div key={translation.id} className={styles.personWorkRow}>
            <BookLink book={translation.work.randomBook} defaultTitle={defaultTitle} />
            <div className={styles.personWorkDetails}>
                <div className={styles.personWorkAuthors}>
                    <h5>
                        <CommaSeparatedPeopleList people={translation.work.authors} name={it => it.name} />
                    </h5>
                </div>
                <div className={styles.personWorkTitle}>
                    <h3>
                        <a href={`/translations/${translation.id}`}>
                                <span>
                                    {translation.title}
                                </span>
                        </a>
                    </h3>
                </div>
                <div className={styles.personWorkTitle}>
                    <h4>
                        <a href={`/works/${translation.work.id}`}>
                                <span>
                                    {translation.work.title}
                                </span>
                        </a>
                    </h4>
                </div>
            </div>
            <div className={styles.personWorkRating}>
                <p/>
            </div>
        </div>
    )
}

export default PersonTranslation
