import styles from "./css/Libraries.module.css"
import {useEffect, useState} from "react";
import LibraryService from "../../services/LibraryService";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";
import {FormattedMessage} from "react-intl";

const Libraries = () => {
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false);
    const [libraries, setLibraries] = useState([]);
    useEffect(() => {
        LibraryService.list()
            .then(p => {
                setIsLoaded(true)
                setLibraries(p.data)
            })
            .catch(err => setError(err))
    }, [])
    if (error) {
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        return (
            <div className={styles.librariesBlock}>
                <div>
                    <h2>
                        <FormattedMessage id="labels.libraries.my"/>
                    </h2>
                </div>
                {libraries.map(lib => (
                    <div className="link indent" key={lib.id}>
                        <a href={`/libraries/${lib.id}`}>{lib.name}</a>
                    </div>
                ))}
                <div>&nbsp;</div>
                <div className="link indent">
                    <a href="/libraries/add">
                        <FormattedMessage id="links.library.add"/>
                    </a>
                </div>
            </div>
        )
    }
}

export default Libraries
