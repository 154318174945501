import styles from "../people/css/Person.module.css";

function BookLink({book, defaultTitle}) {
    return(
        <div className={styles.personWorkCover}>
            <Cover book={book} defaultTitle={defaultTitle} />
        </div>
    )
}

function Cover({book, defaultTitle}) {
    if (book) {
        return(
            <a href={`/books/${book.id}`}>
                <img src={`${process.env.REACT_APP_IMAGE_HOST}/books/${book.id}.webp`}
                     alt={book.title} />
            </a>
        )
    } else {
        return(
            <img src={`${process.env.REACT_APP_IMAGE_HOST}/books/0.webp`}
                 alt={defaultTitle} />
        )
    }
}

export default BookLink
