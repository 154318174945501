import {apiClient} from "./Api";

class MarkService {
    list(page, size) {
        const url = new URL(process.env.REACT_APP_API_HOST + "/marks");

        if (page) {
            url.searchParams.append("page", page)
        }

        if (size) {
            url.searchParams.append("size", size)
        }

        return apiClient.get(url.toString())
    }
}

export default new MarkService()
