import {FormattedMessage} from "react-intl";

function PersonBirthDay({born, died, age, sex}) {
    return(
        <>
            <Born born={born} died={died} age={age} sex={sex} />
            <Died died={died} age={age} sex={sex} />
        </>
    )
}

function Born({born, died, age, sex}) {
    if (born) {
        return(
            <p>
                <span className="property">
                    <FormattedMessage id={`properties.born.${sex}`}/>:&nbsp;
                </span>
                <span itemProp="birthDate">
                    {born}
                </span>
                <Age age={age} show={!died}/>
            </p>
        )
    }
}

function Died({died, age, sex}) {
    if (died) {
        return(
            <p>
                <span className="property">
                    <FormattedMessage id={`properties.died.${sex}`} />:&nbsp;
                </span>
                <span itemProp="deathDate">
                    {died}
                </span>
                <Age age={age} show={true} />
            </p>
        )
    }
}

function Age({age, show}) {
    if (age && show) {
        return (
            <span className="suffix">
                &nbsp;(
                {age}&nbsp;
                <FormattedMessage id="suffixes.years" />
                )
            </span>
        )
    }
}

export default PersonBirthDay
