import styles from "./css/PersonProperties.module.css";
import PersonCountry from "./PersonCountry";
import PersonName from "./PersonName";
import PersonBirthDay from "./PersonBirthDay";
import PersonLabels from "./PersonLabels";

function PersonProperties({properties}) {
    return(
        <div className={styles.personProperties}>
            <PersonName name={properties.name} originalName={properties.originalName} />
            <div className={styles.personAdditionalProperties}>
                <PersonCountry country={properties.country} />
                <PersonBirthDay
                    born={properties.born}
                    died={properties.died}
                    age={properties.age}
                    sex={properties.sex} />
            </div>
            <PersonLabels roles={properties.roles} />
        </div>
    )
}

export default PersonProperties
