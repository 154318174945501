import {useEffect, useRef, useState} from "react";
import styles from "./css/AddItem.module.css"
import InlineAutocomplete from 'react-inline-autocomplete';
import 'react-inline-autocomplete/dist/index.css';
import SearchService from "../../services/SearchService";
import TranslationService from "../../services/TranslationService";
import WorkService from "../../services/WorkService";
import {useIntl} from "react-intl";

function AdvancedBookPositionSearchableInput({contents, updater}) {
    const intl = useIntl()
    const [formFields, setFormFields] = useState([]);

    const [authorDataSource, setAuthorDataSource] = useState([]);
    const [workDataSource, setWorkDataSource] = useState([]);
    const [author, setAuthor] = useState(null);
    const [titleInput, setTitleInput] = useState('');

    const authorRef = useRef();
    const titleRef = useRef();

    useEffect(() => {
        setFormFields([])
        contents.forEach(item => {
            if (item.translationId) {
                return TranslationService.getById(item.translationId)
                    .then(p => p.data)
                    .then(p => {
                        return (
                            {
                                value: item.translationId,
                                text: p.title,
                                obj: {
                                    position: item.position || 1,
                                    workId: item.workId,
                                    translationId: item.translationId,
                                    authors: p.work.authors,
                                    title: p.title,
                                    addition: item.addition || false
                                }
                            }
                        )
                    })
                    .then(p => setFormFields(prev => [...prev, p]))
            } else if (item.workId) {
                return WorkService.getById(item.workId)
                    .then(p => p.data)
                    .then(p => {
                        return (
                            {
                                value: item.workId,
                                text: p.title,
                                obj: {
                                    position: item.position || 1,
                                    workId: item.workId,
                                    translationId: item.translationId,
                                    authors: p.authors,
                                    title: p.title,
                                    addition: item.addition || false
                                }
                            }
                        )
                    })
                    .then(p => setFormFields(prev => [...prev, p]))
            }
        })
    }, [contents])

    const onAuthorChange = (value) => {
        if (value.length > 2) {
            SearchService.searchByName(value, "person")
                .then(result => result.data)
                .then(list => list.map(i => {return {text: i.name, value: i.id}}))
                .then(list => setAuthorDataSource(list))
                .catch(() => setAuthorDataSource([]))
        }
    };

    const onAuthorSelect = (item) => {
        setAuthor(item.value)
        titleRef.current.focus()
    };

    const onWorkChange = (value) => {
        setTitleInput(value)
        if (author && value.length > 2) {
            SearchService.searchPersonWorkByTitle(value, author)
                .then(result => result.data)
                .then(list => list.map(i => {return {
                    text: i.title,
                    value: (i.translationId ? i.translationId : i.workId),
                    obj: i}}))
                .then(list => setWorkDataSource(list))
                .catch(() => setWorkDataSource([]))
        }
    };

    const onWorkSelect = (item) => {
        const data = [...formFields];
        data.push(item)
        setForm(data)
        setTitleInput('')
    };

    const setForm = (items) => updater(items.map((item, i) => {
        return {
            position: i + 1,
            workId: item.obj.workId,
            translationId: item.obj.translationId || null,
            addition: item.obj.addition || false
        }
    }))

    const handleChangeAdditionProperty = (index, value) => {
        const data = [...formFields];
        data[index].obj.addition = value.target.checked;
        setForm(data)
    }

    return (
        <div className={styles.row}>
            <div className={`${styles.buttonInside} ${styles.mainInRow} ${styles.inputGroup}`}>
                {formFields.length > 0 &&
                    <ul>
                        {formFields.sort((a, b) => {return a.obj.position - b.obj.position;})
                            .map((item, index) =>
                                <li key={index}>
                                    <label>
                                        <input
                                            title={intl.formatMessage({id: "tooltip.book.contents.addition"})}
                                            type="checkbox"
                                            checked={item.obj.addition}
                                            onChange={value => handleChangeAdditionProperty(index, value)} />
                                        <nobr>
                                            {`${item.obj.authors.map((a, i) => (i > 0 ? " " : "") + a.name)}. ${item.text} (#${item.value})`}
                                        </nobr>
                                    </label>
                                </li>
                            )}
                    </ul>
                }
                <InlineAutocomplete
                    ref={authorRef}
                    dataSource={authorDataSource}
                    onChange={onAuthorChange}
                    onSelect={item => onAuthorSelect(item)}
                />
                <InlineAutocomplete
                    disabled={!author}
                    ref={titleRef}
                    dataSource={workDataSource}
                    onChange={onWorkChange}
                    onSelect={item => onWorkSelect(item)}
                    value={titleInput}
                />
            </div>
        </div>
    )
}

export default AdvancedBookPositionSearchableInput
