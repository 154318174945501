import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./Layout";
import NotFound from "./components/error/NotFound";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Book from "./components/book/Book";
import Feedback from "./components/feedback/Feedback";
import People from "./components/people/People";
import Person from "./components/people/Person";
import Publisher from "./components/publisher/Publisher";
import Work from "./components/work/Work";
import Translation from "./components/translation/Translation";
import Login from "./components/auth/Login";
import Registration from "./components/auth/Registration";
import Search from "./components/search/Search";
import ErrorBlock from "./components/error/ErrorBlock";
import {Context} from "./stores/UserStore";
import Logout from "./components/auth/Logout";
import Activation from "./components/auth/Activation";
import Profile from "./components/profile/Profile";
import Libraries from "./components/library/Libraries";
import Issues from "./components/issue/Issues";
import Issue from "./components/issue/Issue";
import AuthService from "./services/AuthService";
import AddBook from "./components/book/AddBook";
import AddPerson from "./components/people/AddPerson";
import AddWork from "./components/work/AddWork";
import AddTranslation from "./components/translation/AddTranslation";
import Admin from "./components/admin/Admin";
import {useContext} from "react";
import Library from "./components/library/Library";
import AddLibrary from "./components/library/AddLibrary";
import Marks from "./components/rating/Marks";
import AddMovie from "./components/work/AddMovie";
import Reviews from "./components/review/Reviews";

const publicRoutes = () => <>
    <Route index element={<Home/>}/>
    <Route path="about" element={<About/>}/>
    <Route path="books/:bookId" element={<Book/>}/>
    <Route path="feedback" element={<Feedback/>}/>
    <Route path="people" element={<People/>}/>
    <Route path="people/:personId" element={<Person/>}/>
    <Route path="publishers/:publisherId" element={<Publisher/>}/>
    <Route path="works/:workId" element={<Work/>}/>
    <Route path="translations/:translationId" element={<Translation/>}/>
    <Route path="login" element={<Login/>}/>
    <Route path="register" element={<Registration/>}/>
    <Route path="search" element={<Search/>}/>
    <Route path="error" element={<ErrorBlock/>}/>
</>;

const authRoutes = (isLoggedIn) => {
    if (isLoggedIn) {
        return <>
            <Route path="logout" element={<Logout/>}/>
            <Route path="activate" element={<Activation/>}/>
            <Route path="profile" element={<Profile/>}/>
            <Route path="libraries" element={<Libraries/>}/>
            <Route path="libraries/add" element={<AddLibrary/>}/>
            <Route path="libraries/:libraryId" element={<Library/>}/>
            <Route path="issues" element={<Issues/>}/>
            <Route path="issues/:issueId" element={<Issue/>}/>
            <Route path="marks" element={<Marks/>}/>
            <Route path="reviews" element={<Reviews/>}/>
        </>;
    }
};

const adminRoutes = () => {
    if (AuthService.isAdmin()) {
        return <>
            <Route path="works/:workId/books/add" element={<AddBook/>}/>
            <Route path="translations/:translationId/books/add" element={<AddBook/>}/>
            <Route path="people/add" element={<AddPerson/>}/>
            <Route path="people/:personId/works/add" element={<AddWork/>}/>
            <Route path="works/:workId/translations/add" element={<AddTranslation/>}/>
            <Route path="works/:workId/movies/add" element={<AddMovie/>}/>
            <Route path="admin" element={<Admin/>}/>
        </>;
    }
};

const LectoremRoutes = () => {
    const [state] = useContext(Context);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    {publicRoutes()}
                    {authRoutes(state.isLoggedIn)}
                    {adminRoutes()}
                    <Route path="*" element={<NotFound/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    )

}

export default LectoremRoutes
