import styles from "./css/OneLetterPeople.module.css";

import OneLetterPeopleItem from "./OneLetterPeopleItem";

function OneLetterPeople(props) {
    return (
        <div className={styles.letterContainer}>
            <div>
                <h3>{props.name}</h3>
            </div>
            <div className={styles.letterPeopleContainer}>
                {props.people.map(person => (
                    <OneLetterPeopleItem key={person.id} person={person}/>
                ))}
            </div>
        </div>
    )
}

export default OneLetterPeople
