import {FormattedMessage} from "react-intl";
import CommaSeparatedPeopleList from "../people/CommaSeparatedPeopleList";

function TranslationDetails({from, translators}) {
    return(
        <span>
            {from !== "rus" ?
                <>
                    <FormattedMessage id="prefix.translation.from.small"/>
                    &nbsp;
                    <FormattedMessage id={`languages.from.${from}`}/>
                </>
                :
                <>
                    <FormattedMessage id="prefix.translation.small"/>
                </>
            }
            &nbsp;
            <CommaSeparatedPeopleList people={translators} name={it => it.conjugatedName}/>
        </span>
    )
}

export default TranslationDetails
