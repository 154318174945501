import {authClient} from "./Api";

class AuthService {
    login(username, password) {
        return authClient
            .post(process.env.REACT_APP_AUTH_API_HOST + "/signin", {
                username,
                password
            })
            .then(response => {
                this.saveToken(response);

                return response.data;
            });
    }

    saveToken(response) {
        if (response.data.token) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }
    }

    logout() {
        localStorage.removeItem("user");
    }

    register(username, email, password) {
        return authClient.post(process.env.REACT_APP_AUTH_API_HOST + "/signup", {
            username,
            email,
            password
        });
    }

    getToken() {
        return this.getCurrentUser()?.token;
    }

    getRefreshToken() {
        return this.getCurrentUser()?.refreshToken;
    }

    activate(userId) {
        return authClient.put(process.env.REACT_APP_AUTH_API_HOST + "/" + userId + "/activate", {});
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('user'))
    }

    isAdmin() {
        let currentUser = this.getCurrentUser();
        return currentUser && currentUser.roles && currentUser.roles.includes("ROLE_ADMIN")
    }
}

export default new AuthService();
