import styles from "./css/Mark.module.css";
import {FormattedMessage, useIntl} from "react-intl";
import WorkService from "../../services/WorkService";

const Star = ({workId, mark, setMark, setRating, position}) => {
    const intl = useIntl()
    const handleChange = (e) => {
        const newMark = e.target.value
        setMark(newMark)
        WorkService.rate(workId, newMark)
            .then(i => i.data)
            .then(i => setRating({marks: i.marks, rating: i.rating}))
    };

    return <>
        <input className={styles.radioInput}
               type="radio"
               id={`star${position}-${workId}`}
               name={`star-input-${workId}`}
               value={position}
               checked={mark === position}
               onChange={handleChange}
        />
        <label className={styles.radioLabel}
               htmlFor={`star${position}-${workId}`}
               title={intl.formatMessage({id: `tooltip.marks.${position}`})}>
            <FormattedMessage id={`tooltip.marks.${position}`}/>
        </label>
    </>
}

export default Star
