import styles from "./css/PersonWork.module.css";
import BookLink from "../book/BookLink";
import Mark from "../rating/Mark";
import {useState} from "react";
import {FormattedNumber} from "react-intl";

function PersonWork({work}) {
    const [rating, setRating] = useState(work.rating)
    const defaultTitle = work.mostPublishedTranslation ? work.mostPublishedTranslation : work.title
    return(
        <div className={styles.personWorkRow}>
            <BookLink book={work.randomBook} defaultTitle={defaultTitle} />
            <div className={styles.personWorkDetails}>
                <div className={styles.personWorkTitle}>
                    <h3>
                        <a href={`/works/${work.id}`}>
                            <span>
                                {work.mostPublishedTranslation}
                            </span>
                        </a>
                    </h3>
                </div>
                <div className={styles.personWorkTitle}>
                    <h4>
                        <a href={`/works/${work.id}`}>
                            <span>
                                {work.title}
                            </span>
                        </a>
                    </h4>
                </div>
            </div>
            <Mark workId={work.id} currentValue={work.mark?.mark.toString()} setRating={setRating}/>
            <div className={styles.personWorkRating}>
                <div>
                    {rating.rating > 0 ? (
                        <FormattedNumber value={rating.rating} minimumFractionDigits={2} />
                    ) : (
                        <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PersonWork
