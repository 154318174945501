function CommaSeparatedPeopleList({people, name}) {
    return(
        <>
            {people.map((person, i) => [
                i > 0 && ", ",
                <a key={person.id} href={`/people/${person.id}`}>
                    {name(person)}
                </a>
            ])}
        </>

    )
}

export default CommaSeparatedPeopleList
