import styles from "./css/Error.module.css";
import {FormattedMessage} from "react-intl";

const Searching = () => {
    return(
        <div className={styles.errorContainer}>
            <div className={styles.error}>
                <h1>
                    <FormattedMessage id="errors.searching" />
                </h1>
            </div>
        </div>
    );
};

export default Searching;
