import styles from "./css/WorkReviews.module.css";
import MDEditor from "@uiw/react-md-editor";
import {FormattedDate, FormattedTime} from "react-intl";

export default function Review({review}) {
    return <section className={styles.review}>
        <h4>
            {review.author.username}
            <span className={styles.date}>
                &nbsp;(
                <FormattedDate value={review.createdAt} />
                &nbsp;
                <FormattedTime value={review.createdAt} />
                )&nbsp;
                {[1, 2, 3, 4, 5].map(i =>
                    <span key={i} className={i > review.mark ? styles.starInactive : styles.starActive}>
                        ★
                    </span>
                )}
            </span>
        </h4>
        <MDEditor.Markdown source={review.body}/>
    </section>;
}
