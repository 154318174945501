import styles from "./css/Cover.module.css";

function Cover(props) {
    return(
        <div className={styles.bookCoverImage}>
            <img src={`${process.env.REACT_APP_IMAGE_HOST}/books/${props.id}.webp`} alt={props.title}/>
        </div>
    )
}

export default Cover
