import {FormattedMessage} from "react-intl";

function PersonCountry({country}) {
    if (country && country !== "RU") {
        return(
            <p>
            <span className="property">
                <FormattedMessage id="properties.country" />:&nbsp;
            </span>
                <span itemProp="nationality">
                <FormattedMessage id={`countries.${country}`} />
            </span>
            </p>
        )
    }

}

export default PersonCountry
