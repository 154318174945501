import styles from "./css/Autocomplete.module.css";
import {useEffect, useState} from "react";
import CountryService from "../../services/CountryService";

function CountrySelector({updater}) {
    useEffect(() => {
        CountryService.countries()
            .then(res => setCountries(res.data))
    }, [])
    const [countries, setCountries]  = useState([])
    const [filteredCountries, setFilteredCountries] = useState([])
    const [country, setCountry] = useState('');
    function handleChange(event) {
        let value = event.target.value;
        if (!value || value.length === 0) {
            setFilteredCountries([])
        } else {
            setFilteredCountries(countries.filter(i => i.name.toLowerCase().startsWith(value.toLowerCase())))
        }
        setCountry(value);
    }
    function handleOnClick(item) {
        updater(item.id)
        setCountry(item.name)
        setFilteredCountries([])
    }
    return(
        <div className={styles.autocomplete}>
            <input type="text" id="country" name="country" required
                   value={country} onChange={handleChange} />
            <div id="country-autocomplete-list" className={styles.autocompleteItems}>
                {filteredCountries.map(c => (
                    <div key={c.id} onClick={() => handleOnClick(c)}>
                        {c.name}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CountrySelector
