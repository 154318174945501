import {createRef, useEffect, useState} from "react";
import styles from "./css/AddItem.module.css"
import {FormattedMessage, useIntl} from "react-intl";
import InlineAutocomplete from 'react-inline-autocomplete';
import 'react-inline-autocomplete/dist/index.css';
import SearchService from "../../services/SearchService";

function SearchableInput({updater, type, initialAuthor}) {
    let intl = useIntl();

    const [formFields, setFormFields] = useState([
        {value: '', text: ''}
    ])

    useEffect(() => {
        if (initialAuthor) {
            setFormFields([{value: initialAuthor.id, text: initialAuthor.name}])
        }
    }, [initialAuthor])

    const addFields = (e) => {
        e.preventDefault()
        let object = {
            value: '',
            text: ''
        }

        setFormFields([...formFields, object])
    }

    const removeFields = (index) => {
        if (formFields.length === 1) {
            console.error(intl.formatMessage({id: "errors.at.least.one.author"}))
        } else {
            let data = [...formFields];
            data.splice(index, 1)
            setFormFields(data)
            setForm(data)
        }
    }

    const [dataSource, setDataSource] = useState([]);

    const ref = createRef();
    const onChange = (value, index) => {
        const data = [...formFields];
        data[index].text = value
        setFormFields(data)
        if (value.length > 2) {
            SearchService.searchByName(value, type)
                .then(result => result.data)
                .then(list => list.map(i => {return {text: i.name, value: i.id}}))
                .then(list => setDataSource(list))
                .catch(() => setDataSource([]))
        }
    };
    const onSelect = (item, index) => {
        const data = [...formFields];
        data[index] = item
        setFormFields(data)
        setForm(data)
    };
    const setForm = (items) => {
        updater(items.map(item => {
            return item.value;
        }))
    }

    return (
        <>
            {formFields.map((form, index) => {
                return (
                    <div key={index} className={styles.row}>
                        <div className={`${styles.buttonInside} ${styles.mainInRow} ${styles.inputGroup}`}>
                            <InlineAutocomplete
                                ref={ref}
                                dataSource={dataSource}
                                onChange={value => onChange(value, index)}
                                onSelect={item => onSelect(item, index)}
                                value={form.text}
                            />
                            <button style={{zIndex: 100}} onClick={() => removeFields(index)}>🗙</button>
                        </div>
                    </div>
                )
            })}
            <button onClick={addFields}>
                <FormattedMessage id="properties.add.more"/>
            </button>
        </>
    )
}

export default SearchableInput
