import styles from "./css/Search.module.css"
import {useEffect, useState} from "react";
import NotFound from "../error/NotFound";
import ErrorBlock from "../error/ErrorBlock";
import {useSearchParams} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import Searching from "../error/Searching";

function Search() {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState(null);
    const [isSearchFinished, setIsSearchFinished] = useState(false);
    const [searchResults, setSearchResults] = useState({empty: true})
    useEffect(() => {
        const request = searchParams.get('request')
        fetch(`${process.env.REACT_APP_API_HOST}/search?request=` + request)
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                throw new Error(res.status.toString())
            })
            .then(
                (result) => {
                    setIsSearchFinished(true);
                    setSearchResults(result);
                    document.title = "Lectorem";
                },
                (err) => {
                    setIsSearchFinished(true);
                    setError(err);
                }
            )
    }, [searchParams])
    if (error) {
        if (error.message === "404") {
            return <NotFound/>
        }
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isSearchFinished) {
        return <Searching/>;
    } else {
        return(
            <div className={styles.searchBlocks}>
                {searchResults.empty &&
                    <p className={styles.notFound}>
                        <FormattedMessage id={`labels.search.results.nothing`}/>: &#171;{searchParams.get('request')}&#187;
                    </p>
                }
                {searchResults.people && searchResults.people.length > 0 &&
                    <div className={styles.searchPeopleBlock}>
                        <div className={styles.searchPersonRow}>
                            <p className={styles.searchPersonBlockHeader}>
                                <FormattedMessage id={`labels.search.results.people`}/>
                            </p>
                        </div>
                        {searchResults.people.map((person) => [
                            <div key={person.id} className={styles.searchPersonRow}>
                                <div className={styles.searchPersonPhoto}>
                                    <a href={`/people/${person.id}`}>
                                        <img src={`${process.env.REACT_APP_IMAGE_HOST}/people/${person.id}.webp`}
                                             alt={person.name} />
                                    </a>
                                </div>
                                <div className={styles.searchPersonDetails}>
                                    <div className={styles.searchPersonName}>
                                        <h3>
                                            <span>
                                                {person.name}
                                            </span>
                                        </h3>
                                    </div>
                                    <div className={styles.searchPersonName}>
                                        <h4>
                                            <span>
                                                {person.originalName}
                                            </span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        ])}
                    </div>
                }
                {searchResults.books && searchResults.books.length > 0 &&
                    <div className={styles.searchBooksBlock}>
                        <div className={styles.searchBookRow}>
                            <p className={styles.searchBookBlockHeader}>
                                <FormattedMessage id="labels.search.results.book"/>
                            </p>
                        </div>
                        {searchResults.books.map((book) => [
                            <div key={book.id} className={styles.searchBookRow}>
                                <div className={styles.searchBookCover}>
                                    <a href={`/books/${book.id}`}>
                                        <img src={`${process.env.REACT_APP_IMAGE_HOST}/books/${book.id}.webp`}
                                             alt={book.title} />
                                    </a>
                                </div>
                                <div className={styles.searchBookDetails}>
                                    <div className={styles.searchBookTitle}>
                                        <h3>
                                            <span>
                                                {book.title}
                                            </span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        ])}
                    </div>
                }
            </div>
        )
    }
}

export default Search
