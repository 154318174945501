import styles from "./css/LibrariesButtons.module.css";
import LibraryService from "../../services/LibraryService";
import {useEffect, useState} from "react";
import {useIntl} from "react-intl";

const BucketListButton = ({bookId, library}) => {
    const intl = useIntl()
    const [inLibrary, setInLibrary] = useState(false)
    useEffect(() => {
        if (library !== undefined) {
            if (library.inLibrary) {
                setInLibrary(true)
            }
        }
    }, [library]);
    const handleOnClick = () => {
        if (inLibrary) {
            LibraryService.removeBook(library.library.id, bookId)
                .then(() => setInLibrary(false))
        } else {
            if (library === undefined) {
                LibraryService.create(
                    {
                        name: intl.formatMessage({id: "links.book.add.to.bucket.list"}),
                        type: "WISH_LIST",
                        accessType: "PUBLIC"
                    })
                    .then(r => r.data)
                    .then(libraryId => LibraryService.addBook(libraryId, bookId))
                    .then(() => setInLibrary(true))
            } else {
                LibraryService.addBook(library.library.id, bookId)
                    .then(() => setInLibrary(true))
            }
        }
    }
    return (
        <div onClick={handleOnClick} title={intl.formatMessage({id: "tooltip.libraries.bucket.add"})}
             className={styles.librariesButton}>
            <svg className={`${styles.bucketList} ${inLibrary ? styles.active : styles.inactive}`} viewBox="0 0 32 32">
                <path className="sharpcorners_een" d="M10,27h4c0,1.105-0.895,2-2,2S10,28.105,10,27z M23,29c1.105,0,2-0.895,2-2h-4
                        C21,28.105,21.895,29,23,29z M30,9H6.819l-1-5H2v2h2.181l4,20H26v-2H9.819l-0.6-3H26L30,9z"/>
            </svg>
        </div>
    )
}

export default BucketListButton
