import React from 'react';
import ReactDOM from 'react-dom/client';

import {IntlProvider} from 'react-intl';
import Ukrainian from './lang/uk.json';

import "./App.css"
import UserStore from "./stores/UserStore";

import LectoremRoutes from "./LectoremRoutes";

const locale = navigator.language;

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <UserStore>
            <IntlProvider locale={locale} messages={Ukrainian}>
                <LectoremRoutes />
            </IntlProvider>
        </UserStore>
    </React.StrictMode>
);
