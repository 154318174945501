import {useState, useEffect} from "react";

import {FormattedMessage, useIntl} from "react-intl";
import styles from "../form/css/Form.module.css"
import ErrorBlock from "../error/ErrorBlock";
import AuthService from "../../services/AuthService";
import {Navigate} from "react-router-dom";

function Registration() {
    const intl = useIntl()
    useEffect(() => {
        document.title = "Lectorem: " + intl.formatMessage({id: "menus.registration"});
    }, [intl])
    const [isRegistered, setIsRegistered] = useState(false)
    const [error, setError] = useState(null)
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        password: ""
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        AuthService.register(formData.username, formData.email, formData.password)
            .then(
                () => {
                    setIsRegistered(true)
                },
                err => {
                    if (err.response && err.response.data  && err.response.data.message) {
                        setError(err.response.data.message)
                    } else {
                        setError(err)
                    }
                })
    }
    if (isRegistered) {
        return <Navigate to="/login" />
    } else if(error) {
        console.error(error)
        return <ErrorBlock/>;
    } else {
        return(
            <div className={styles.formContainer}>
                <form onSubmit={handleSubmit}>
                    <label>
                        <FormattedMessage id="properties.name" />
                        <input className={styles.formInput}
                               value={formData.name}
                               onChange={(e) => setFormData({...formData, username: e.target.value})}
                               type="text"
                               name="username"
                               id="username"
                               required />
                    </label>
                    <label>
                        <FormattedMessage id="properties.email" />
                        <input className={styles.formInput}
                               value={formData.name}
                               onChange={(e) => setFormData({...formData, email: e.target.value})}
                               type="email"
                               name="email"
                               id="email"
                               required />
                    </label>
                    <label>
                        <FormattedMessage id="properties.password" />
                        <input className={styles.formInput}
                               value={formData.password}
                               onChange={(e) => setFormData({...formData, password: e.target.value})}
                               type="password"
                               name="password"
                               id="password"
                               required />
                    </label>
                    <input type="submit" value={intl.formatMessage({id: "properties.register"})} />
                </form>
            </div>
        )
    }
}

export default Registration
