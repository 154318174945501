import styles from "./css/WorkReviews.module.css";
import MDEditor, {commands} from "@uiw/react-md-editor";
import {useState} from "react";
import ReviewService from "../../services/ReviewService";
import {useIntl} from "react-intl";
import AuthService from "../../services/AuthService";

function AddReview({worksId}) {
    const [value, setValue] = useState("");
    const intl = useIntl()

    const publish = {
        name: "publish",
        keyCommand: "publish",
        buttonProps: {
            "aria-label": intl.formatMessage({id: "labels.publish"}),
            "title": intl.formatMessage({id: "labels.publish"})
        },
        icon: (
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 64 64">
                <path d="M27 55L6 33 9 29 26 41 55 12 59 16z"></path>
            </svg>
        ),
        execute: (state, ) => {
            ReviewService.publish(worksId, state.text)
                .then(i => console.info("Review was saved"))
                .catch(err => console.error(err))
        }
    };

    if (AuthService.getCurrentUser()) {
        return (
            <div className={styles.editor}>
                <MDEditor
                    value={value}
                    onChange={setValue}
                    preview="edit"
                    commands={[
                        commands.bold,
                        commands.italic,
                        commands.strikethrough,
                        commands.divider,
                        commands.quote
                    ]}
                    extraCommands={[
                        publish,
                        commands.divider,
                        commands.codeEdit,
                        commands.codePreview,
                        commands.divider,
                        commands.fullscreen
                    ]}
                    textareaProps={{
                        placeholder: intl.formatMessage({id: "placeholders.newReview"})
                    }}
                />
            </div>)
    }
}

export default AddReview
