import styles from "./css/LibrariesButtons.module.css"
import MyLibraryButton from "./MyLibraryButton";
import BucketListButton from "./BucketListButton";
import OtherLibrariesButton from "./OtherLibrariesButton";
import {useEffect, useState} from "react";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";
import LibraryService from "../../services/LibraryService";
import BookLibrariesList from "./BookLibrariesList";
import AuthService from "../../services/AuthService";


const LibrariesButtons = ({bookId}) => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [libraries, setLibraries] = useState([])
    const [myLibrary, setMyLibrary] = useState(null)
    const [bucketList, setBucketList] = useState(null)
    const [expandList, setExpandList] = useState(false)
    useEffect(() => {
        function loadLibraries() {
            LibraryService.listForBook(bookId)
                .then(p => {
                    const libs = p.data;
                    setLibraries(libs.filter(lib => lib.library.type !== "OWN" && lib.library.type !== "WISH_LIST"));
                    setMyLibrary(libs.filter(lib => lib.library.type === "OWN")[0]);
                    setBucketList(libs.filter(lib => lib.library.type === "WISH_LIST")[0]);
                })
                .catch(err => setError(err))
                .finally(() => setIsLoaded(true))
        }
        if (AuthService.isAdmin()) {
            loadLibraries();
        }
    }, [bookId])
    if (!AuthService.isAdmin()) {
        return "";
    } else if (error) {
        console.error(error.message)
        return <ErrorBlock/>
    } else if (!isLoaded) {
        return <Loading/>
    } else {
        return (
            <div className={styles.librariesButtons}>
                <MyLibraryButton bookId={bookId} library={myLibrary}/>
                <BucketListButton bookId={bookId} library={bucketList}/>
                <OtherLibrariesButton expand={() => setExpandList(current => !current)} expanded={expandList}/>
                <BookLibrariesList bookId={bookId} expandList={expandList} libraries={libraries}/>
            </div>
        )
    }
}

export default LibrariesButtons
