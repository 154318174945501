import styles from "../form/css/AddItem.module.css"
import {FormattedMessage, useIntl} from "react-intl";
import {useState, useEffect} from "react";
import LanguageSelector from "../form/LanguageSelector";
import SearchableInput from "../form/SearchableInput";
import AuthService from "../../services/AuthService";
import ErrorBlock from "../error/ErrorBlock";
import {Navigate, useParams} from "react-router-dom";
import WorkService from "../../services/WorkService";
import PeopleService from "../../services/PeopleService";

function AddWork() {
    const intl = useIntl()

    const {personId} = useParams()

    const [initAuthor, setInitAuthor] = useState({})

    const [id ,setId] = useState(null)
    const [error, setError] = useState(null)
    const [work, setWork] = useState({
        title: "",
        language: "",
        authors: []
    })

    useEffect(() => {
        PeopleService.getById(personId)
            .then(r => r.data)
            .then(r => {
                setWork(prev => ({title: prev.title, language: prev.language, authors: [personId]}))
                setInitAuthor(r)
            })
    }, [personId])

    function onSubmit() {
        return (e) => {
            e.preventDefault();
            WorkService.create(work)
                .then(p => setId(p.data))
                .catch(err => setError(err))
        };
    }

    if (id) {
        if (AuthService.isAdmin()) {
            return <Navigate to={`/works/${id}`} />
        } else {
            return <Navigate to={`/issues`} />
        }
    } else if (error) {
        console.error(error)
        return <ErrorBlock />
    } else {
        if (AuthService.getCurrentUser()) {
            return(
                <div className={styles.formContainer}>
                    <form onSubmit={onSubmit()} >
                        <div className={styles.addItemContainer}>
                            <div className={styles.inputGroup}>
                                <SearchableInput updater={it => setWork({...work, authors: it})} type="person" initialAuthor={initAuthor}/>
                                <div className={styles.row}>
                                    <div className={`${styles.inputGroup} ${styles.mainInRow}`}>
                                        <label htmlFor="title">
                                            <FormattedMessage id="properties.originalTitle" />
                                        </label>
                                        <input type="text" id="title" name="title" required
                                               onChange={(e) => setWork({...work, title: e.target.value})}
                                               value={work.title} />
                                    </div>
                                    <div className={styles.inputGroup}>
                                        <label htmlFor="language">
                                            <FormattedMessage id="properties.language" />
                                        </label>
                                        <LanguageSelector updater={it => setWork({...work, language: it})} />
                                    </div>
                                </div>
                            </div>
                            <input type="submit" value={intl.formatMessage({id: "properties.submit"})} />
                        </div>
                    </form>
                </div>
            )
        } else {
            return <ErrorBlock />
        }
    }
}

export default AddWork
