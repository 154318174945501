import styles from "./css/Header.module.css"
import {useIntl, FormattedMessage} from "react-intl";
import LoginMenu from "./LoginMenu";

const Header = () => {
    const intl = useIntl()
    return (
        <nav className={styles.navbar}>
            <div className={styles.navbarContainer + " " + styles.headerContainer}>
                <input type="checkbox" name="" id="" />
                <div className={styles.hamburgerLines}>
                    <span className={styles.line + " " + styles.line1}/>
                    <span className={styles.line + " " + styles.line2}/>
                    <span className={styles.line + " " + styles.line3}/>
                </div>
                <ul className={styles.menuItems}>
                    <li>
                        <a href="/people">
                            <FormattedMessage id="menus.people"/>
                        </a>
                    </li>
                    <li>
                        <a href="/about">
                            <FormattedMessage id="menus.about"/>
                        </a>
                    </li>
                    <LoginMenu />
                </ul>
                <h1 className={styles.logo}><a href="/">LECTOREM</a></h1>
                <div className={styles.inputIcons}>
                    <form action="/search">
                        <strong className={styles.icon}>&#x1F50E;&#xFE0E;</strong>
                        <input className={styles.inputField} type="search" name="request" pattern=".*\S.*"
                               placeholder={intl.formatMessage({id: "labels.search"})} required />
                    </form>
                </div>
            </div>
        </nav>
    )
}

export default Header
