import {FormattedMessage} from "react-intl";
import {useContext} from "react";
import {Context} from "../../stores/UserStore";
import AuthService from "../../services/AuthService";

function LoginMenu() {
    const [state] = useContext(Context);
    if (state.isLoggedIn) {
        const userInfo = AuthService.getCurrentUser()
        return(
            <li>
                <a href="/profile">
                    {userInfo.username}
                </a>&nbsp;
                <a href="/logout">
                    <FormattedMessage id="menus.logout"/>
                </a>
            </li>
        )
    }
    return(
        <li>
            <a href="/login">
                <FormattedMessage id="menus.login"/>
            </a>
        </li>
    )
}

export default LoginMenu
