import styles from "./css/FeedbackView.module.css"
import {apiClient} from "../../services/Api";
import {useState} from "react";
import {useIntl} from "react-intl";

function FeedbackView({feedback, isClosed}) {
    const intl = useIntl();
    const [closed, setClosed] = useState(isClosed)
    const handleSpam = (e) => {
        e.preventDefault()
        apiClient.put(`/feedbacks/${feedback.id}/spam`)
            .then(ignore => setClosed(true))
    }
    const handleClose = (e) => {
        e.preventDefault()
        apiClient.put(`/feedbacks/${feedback.id}/approve`)
            .then(ignore => setClosed(true))
    }
    return <div className={styles.feedback}>
        <div>
            <span className={styles.name}>{feedback.name}</span> <span className={styles.email}>({feedback.email})</span>
        </div>
        <div>
            {feedback.message}
        </div>
        {!closed &&
            <div>
                <input type="button" onClick={handleClose} value={intl.formatMessage({id: "properties.close"})} />
                <input type="button" onClick={handleSpam} value={intl.formatMessage({id: "properties.spam"})} />
            </div>
        }
    </div>
}

export default FeedbackView
