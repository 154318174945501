import styles from "./css/Translation.module.css";
import TranslationDetails from "../translation/TranslationDetails";

function Translation({contents}) {
    if (contents && contents.length === 1) {
        const translation = contents[0].translation;
        if (translation) {
            return(
                <div className={styles.bookTranslators}>
                    <p>
                        <TranslationDetails from={translation.from} translators={translation.translators} />
                    </p>
                </div>
            )
        }
    }
}

export default Translation
