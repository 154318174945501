import styles from "../work/css/WorkReviews.module.css";
import MDEditor from "@uiw/react-md-editor";
import {FormattedDate, FormattedTime} from "react-intl";
import CommaSeparatedPeopleList from "../people/CommaSeparatedPeopleList";

export default function Review({review}) {
    return <section className={styles.review}>
        <h4>
            <CommaSeparatedPeopleList people={review.work.authors} name={it => it.name} />
            .&nbsp;
            <a className="link" href={`/works/${review.work.id}`}>{review.work.mostPublishedTranslation ?
                review.work.mostPublishedTranslation :
                review.work.title}</a>
            <span className={styles.date}>
                &nbsp;(
                <FormattedDate value={review.createdAt} />
                &nbsp;
                <FormattedTime value={review.createdAt} />
                )&nbsp;
                {[1, 2, 3, 4, 5].map(i =>
                    <span key={i} className={i > review.mark ? styles.starInactive : styles.starActive}>
                        ★
                    </span>
                )}
            </span>
        </h4>
        <MDEditor.Markdown source={review.body}/>
    </section>;
}
