import {useState, useEffect} from 'react';
import {useIntl, FormattedMessage} from "react-intl";

import styles from "./css/People.module.css"

import OneLetterPeople from "./OneLetterPeople";
import Pagination from "../pagination/Pagination";
import {useSearchParams} from "react-router-dom";
import Loading from "../error/Loading";
import ErrorBlock from "../error/ErrorBlock";
import AuthService from "../../services/AuthService";

function People() {
    const intl = useIntl()

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [peopleGroups, setPeopleGroups] = useState(null);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        function loadPeople() {
            const page = searchParams.get('page');
            const size = searchParams.get('size');

            const url = new URL(process.env.REACT_APP_API_HOST + "/people");
            if (page) {
                url.searchParams.append("page", page)
            }
            if (size) {
                url.searchParams.append("size", size)
            }
            fetch(url.toString())
                .then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setPeopleGroups(result);
                    },
                    (err) => {
                        setIsLoaded(true);
                        setError(err);
                    }
                )
        }

        document.title = "Lectorem: " +
            intl.formatMessage({id: "labels.writers"}) + ", " +
            intl.formatMessage({id: "labels.translators"}) + ", " +
            intl.formatMessage({id: "labels.artists"});
        loadPeople();
    }, [intl, searchParams])

    if (error) {
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        const content = peopleGroups.content;
        return (
            <>
                <div className={styles.peopleContainer}>
                    <h2>
                        <FormattedMessage id="labels.writers" />
                        , <FormattedMessage id="labels.translators" />
                        , <FormattedMessage id="labels.artists" />
                    </h2>
                    {AuthService.isAdmin() &&
                        <div className="link indent right">
                            <a href={`/people/add`} >
                                <FormattedMessage id="links.person.add" />
                            </a>
                        </div>
                    }
                    {content.map(peopleGroup => (
                        <OneLetterPeople key={peopleGroup.key} name={peopleGroup.key} people={peopleGroup.people}/>
                    ))}
                    <Pagination baseUrl="/people"
                                totalPages={peopleGroups.totalPages}
                                page={peopleGroups.number}
                                size={peopleGroups.size}/>
                </div>
            </>
        )
    }
}

export default People
