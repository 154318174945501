import styles from "./css/Description.module.css";
import {FormattedMessage} from "react-intl";

function Description({description}) {
    if (description) {
        return(
            <div itemProp="abstract" className={styles.bookDescription}>
                <h3>
                    <FormattedMessage id="properties.book.desc"/>
                </h3>
                <p>
                    {description}
                </p>
            </div>
        )
    }
}

export default Description
