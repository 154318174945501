function CommaSeparatedPublisherList({publishers}) {
    return(
        <>
            {publishers.map((publisher, i) => [
                i > 0 && ", ",
                <span key={i}>{`${publisher.city}: `}
                    <a href={`/publishers/${publisher.id}`}>
                        {publisher.name}
                    </a>
                </span>
            ])}
        </>
    )
}

export default CommaSeparatedPublisherList
