import {FormattedMessage} from "react-intl";

function Format({coverType, format}) {
    return(
        <p>
            {coverType &&
                <span>
                    <FormattedMessage id={`cover.types.${coverType}`}/>
                </span>
            }
            {coverType && format &&
                <span>,&nbsp;</span>
            }
            {format &&
                <span>
                    {format}
                </span>
            }
        </p>
    )
}

export default Format
