import styles from "./css/Reviews.module.css";
import {FormattedMessage} from "react-intl";
import {useEffect, useState} from "react";
import ReviewService from "../../services/ReviewService";
import NotFound from "../error/NotFound";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";
import Review from "./Review";
import Pagination from "../pagination/Pagination";
import {useSearchParams} from "react-router-dom";

function Reviews({workId}) {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [reviews, setReviews] = useState([])
    const [totalPages, setTotalPages] = useState(1);
    const [totalReviews, setTotalReviews] = useState(0);
    const [pageable, setPageable] = useState({
        sort: {
            empty: true,
            sorted: false,
            unsorted: true
        },
        offset: 0,
        pageNumber: 0,
        pageSize: 20,
        unpaged: false,
        paged: true
    });

    useEffect(() => {
        const page = searchParams.get('page');
        const size = searchParams.get('size');
        const getReviews = (workId) => {
            ReviewService.list(page, size)
                .then(res =>  res.data)
                .then(result => {
                    setIsLoaded(true);
                    setReviews(result.content);
                    setTotalPages(result.totalPages)
                    setPageable(result.pageable)
                    setTotalReviews(result.totalElements)
                })
                .catch(err => setError(err))
        }

        getReviews(workId)
    }, [workId, searchParams])
    if (error) {
        if (error.message === "404") {
            return <NotFound/>
        }
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        return(
            <div className={styles.reviews}>
                <div>
                    <h2>
                        <FormattedMessage id="labels.reviews" /> ({totalReviews})
                    </h2>
                </div>
                <div>
                    {reviews.map(review =>
                        <Review key={review.work.id} review={review} />
                    )}
                </div>
                <Pagination baseUrl={`/reviews`}
                            totalPages={totalPages}
                            page={pageable.pageNumber}
                            size={pageable.pageSize}/>
            </div>
        )
    }
}

export default Reviews
