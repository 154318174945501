import {apiClient} from "./Api";

class IssueService {
    list() {
        return apiClient.get(process.env.REACT_APP_API_HOST + "/issues");
    }

    getById(id) {
        return apiClient.get(process.env.REACT_APP_API_HOST + "/issues/" + id)
    }
}

export default new IssueService()
