import styles from "./css/Footer.module.css"

import {FormattedMessage} from "react-intl";

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div>
                <p>2022&#65123;{new Date().getFullYear()} © Lectorem</p>
            </div>
            <div>
                <a href="/feedback">
                    <FormattedMessage id="menus.feedback" />
                </a>
            </div>
        </footer>
    )
}

export default Footer
