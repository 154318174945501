import {apiClient} from "./Api";

class ReviewService {

    getByWorksId(workId) {
        return apiClient.get(process.env.REACT_APP_API_HOST + "/works/" + workId + "/reviews")
    }

    publish(workId, body) {
        return apiClient.post(process.env.REACT_APP_API_HOST + "/works/" + workId + "/reviews", {body: body})
    }

    list(page, size) {
        const url = new URL(process.env.REACT_APP_API_HOST + "/reviews");

        if (page) {
            url.searchParams.append("page", page)
        }

        if (size) {
            url.searchParams.append("size", size)
        }

        return apiClient.get(url.toString())
    }
}

export default new ReviewService()
