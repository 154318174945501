import styles from "./css/Marks.module.css"
import {useEffect, useState} from "react";
import ErrorBlock from "../error/ErrorBlock";
import Loading from "../error/Loading";
import {FormattedDate, FormattedMessage} from "react-intl";
import MarkService from "../../services/MarkService";
import CommaSeparatedPeopleList from "../people/CommaSeparatedPeopleList";
import {Link, useSearchParams} from "react-router-dom";
import WorkService from "../../services/WorkService";
import Pagination from "../pagination/Pagination";

const Marks = () => {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false);
    const [marks, setMarks] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalMarks, setTotalMarks] = useState(0);
    const [pageable, setPageable] = useState({
        sort: {
            empty: true,
            sorted: false,
            unsorted: true
        },
        offset: 0,
        pageNumber: 0,
        pageSize: 20,
        unpaged: false,
        paged: true
    });

    useEffect(() => {
        const page = searchParams.get('page');
        const size = searchParams.get('size');

        MarkService.list(page, size)
            .then(p => p.data)
            .then(result => {
                setIsLoaded(true)
                setMarks(result.content)
                setTotalPages(result.totalPages)
                setPageable(result.pageable)
                setTotalMarks(result.totalElements)
            })
            .catch(err => setError(err))
    }, [searchParams])

    const handleDelete = (work) => {
        WorkService.unrate(work.id)
        let array = [...marks];
        let index = array.indexOf(work);
        if (index !== -1) {
            array.splice(index, 1);
            setMarks(array)
        }
    };

    if (error) {
        console.error(error.message)
        return <ErrorBlock/>;
    } else if (!isLoaded) {
        return <Loading/>;
    } else {
        return (
            <div className={styles.marksBlock}>
                <div>
                    <h2>
                        <FormattedMessage id="labels.marks.my"/> ({totalMarks})
                    </h2>
                </div>
                <div>
                    <table>
                        <tbody>
                        {marks.map(work => (
                            <tr className="link" key={work.id}>
                                <td>
                                    <CommaSeparatedPeopleList people={work.authors} name={it => it.name} />
                                    .&nbsp;
                                    <a href={`/works/${work.id}`}>{work.mostPublishedTranslation ? work.mostPublishedTranslation : work.title}</a>
                                </td>
                                <td className="gold">
                                    {work.mark.mark}&nbsp;
                                </td>
                                <td className="gray">
                                    <nobr>
                                        <FormattedDate value={work.mark.date} year="numeric" month="short" day="2-digit"/>
                                    </nobr>
                                </td>
                                <td>
                                    <strong>
                                        <Link onClick={() => handleDelete(work)} to="#">×</Link>
                                    </strong>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <Pagination baseUrl={`/marks`}
                                totalPages={totalPages}
                                page={pageable.pageNumber}
                                size={pageable.pageSize}/>
                </div>
            </div>
        )
    }
}

export default Marks
