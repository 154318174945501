function HorizontalGalleryItem(props) {
    return <div itemScope={true} itemType={props.type.schema}>
        <a href={props.type.path + "/" + props.item.id}>
            <img
                src={process.env.REACT_APP_IMAGE_HOST + props.type.path + "/" + props.item.id + ".webp"}
                alt={props.item.title}
            />
        </a>
        <p itemProp="name">{props.type.id === "book" ? props.item.title : props.item.name}</p>
    </div>;
}

export default HorizontalGalleryItem
