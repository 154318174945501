import {apiClient} from "./Api";

class SearchService {
    searchByName(name, type) {
        switch (type) {
            case "person":
                return apiClient.get(process.env.REACT_APP_API_HOST + "/search/people", { params: { name: name } });
            case "publisher":
                return apiClient.get(process.env.REACT_APP_API_HOST + "/search/publishers", { params: { name: name } });
            default:
                return null;
        }
    }

    searchPersonWorkByTitle(title, authorId) {
        return apiClient.get(process.env.REACT_APP_API_HOST + "/search/people/" + authorId, { params: { title: title } });
    }
}

export default new SearchService()
